import AssetUpload from "@/components/AssetUpload";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { useNode } from "@craftjs/core";
import { Button, Flex } from "antd";
import { StyledCollapse } from "../../travelStyles";

const ServiceItem = ({ service, handleChangeItem, index }) => {
    return <Flex vertical gap="middle">
        <div>
            <label className="block font-bold text-gray-700">Title</label>
            <textarea
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                placeholder="URL (e.g., https://facebook.com/...)"
                value={service.title}
                onChange={(e) => handleChangeItem(index, 'title', e.target.value)}
            />
        </div>

        <div>
            <label className="block font-bold text-gray-700">Content</label>
            <textarea
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                placeholder="URL (e.g., https://facebook.com/...)"
                value={service.content}
                onChange={(e) => handleChangeItem(index, 'content', e.target.value)}
            />
        </div>
    </Flex>
}

const TravelServiceSetting = () => {
    const { actions: { setProp }, title, description, backgroundImage, listItem } = useNode((node) => ({
        props: node.data.props,
        title: node.data.props.title,
        description: node.data.props.description,
        backgroundImage: node.data.props.backgroundImage,
        listItem: node.data.props.listItem
    }));

    const handleRemoveItem = (index) => {
        setProp((props: { listItem: { title: string; content: string }[] }) => {
            const newListItem = [...(props.listItem || [])];
            newListItem.splice(index, 1);
            props.listItem = newListItem;
        }, 1000);
    };

    const handleAddItem = () => {
        setProp((props: { listItem: { title: string; content: string }[] }) => {
            const newListItem = [...(props.listItem || []), { title: 'Title', content: 'Content' }];
            props.listItem = newListItem;
        }, 1000);
    };

    const handleChangeItem = (index: number, field: string, value: string) => {
        setProp((props: { listItem: { title: string; content: string }[] }) => {
            const newListItem = [...(props.listItem || [])];
            newListItem[index][field] = value;
            props.listItem = newListItem;
        }, 1000);
    };

    return <Flex vertical>

        {/* Background Image */}
        <div className="mb-5">
            <label className="block mb-2 font-bold text-gray-700">Background Image</label>
            <Flex className="w-full" align="center" justify="space-between">
                <span>{backgroundImage?.name}</span>
                <AssetUpload
                    onSelect={(file) => {
                        setProp(
                            (props: { backgroundImage: { url: string; name: string } }) =>
                                (props.backgroundImage = { url: file.url, name: file.name }),
                            1000
                        );
                    }}
                >
                    <Button type="link" className="p-0">
                        CHANGE
                    </Button>
                </AssetUpload>
            </Flex>
        </div>


        {/* Title */}
        <div className="mb-5">
            <label className="block mb-2 font-bold text-gray-700">Title</label>
            <textarea
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                value={title}
                onChange={(e) =>
                    setProp((props: { title: string }) => (props.title = e.target.value))
                }
            />
        </div>

        {/* Description */}
        <div className="mb-5">
            <label className="block mb-2 font-bold text-gray-700">Description</label>
            <textarea
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                value={description}
                onChange={(e) => setProp((props: { description: string }) => (props.description = e.target.value))}
            />
        </div>
        {/* List item */}
        <StyledCollapse defaultActiveKey={['0']}
            className="mb-2"
            items={listItem.map((service, index) => ({
                key: index.toString(),
                label: <Flex justify="space-between" align="center">
                    <span className="font-semibold text-gray-600">Service {index + 1}</span>
                    <Button
                        danger
                        type="link"
                        icon={<DeleteOutlined />}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleRemoveItem(index);
                        }}
                    />
                </Flex>,
                children: <ServiceItem
                    service={service}
                    handleChangeItem={handleChangeItem}
                    index={index}
                />
            }))} />

        <Button
            type="dashed"
            className='w-full'
            icon={<PlusOutlined />}
            onClick={handleAddItem}
        >
            Add Service
        </Button>

    </Flex>
}

export default TravelServiceSetting
