import { useNode } from '@craftjs/core';
import { TravelFooterProps } from '../types';
import TravelFooterSetting from './settings/TravelFooterSetting';

function TravelFooter({ logo, leftContent, rightContent, socialMedia, themeColor }: TravelFooterProps) {
  const {
    connectors: { connect },
    id,
  } = useNode();
  return (
    <div
      id={id}
      ref={connect as React.LegacyRef<HTMLDivElement>}
      className="px-4 py-20 md:px-10 lg:px-36 bg-[#0D2436]"
      // style={{ backgroundColor: themeColor }}
    >
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center md:items-end">
        <div className="text-white flex flex-col md:text-left w-[263px]">
          <img className="mb-6 w-[96px] h-[25px]" src={logo.url} alt={logo.name} />
          <p className="leading-relaxed">{leftContent}</p>
        </div>

        <div className="flex flex-col">
          <p className="text-white mt-4 mb-4">{rightContent}</p>
          <div className="flex gap-1">
            {socialMedia.map((item, index) => (
              <a key={index} href={item.link} target="_blank" rel="noopener noreferrer">
                <img src={item.icon} alt={item.link} />
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

TravelFooter.defaultProps = {
  themeColor: "#0D2436"
,  logo: { url: "/images/logo/logo-gtg-white.png", name: "logo-gtg-white" },
  leftContent: "Build a modern and beautiful website with GTG CRM",
  rightContent: "Copyright © 2025.",
  socialMedia: [
    { icon: "/travel/social-google.svg", link: "https://www.google.com" },
    { icon: "/travel/social-instagram.svg", link: "https://www.instagram.com" },
    { icon: "/travel/social-linkin.svg", link: "https://www.linkedin.com" },
  ],
};

TravelFooter.craft = {
  props: TravelFooter.defaultProps,
  related: {
    settings: TravelFooterSetting,
  },
};

export default TravelFooter;
