import { useNode } from '@craftjs/core';
import styled from '@emotion/styled';
import React from 'react';
import { TravelPopularDestinationProps } from '../types';
import TravelPopuplarDestinationSetting from './settings/TravelPopuplarDestinationSetting';

const SAMPLE_DESTINATIONS = [
  {
    image: { url: '/travel/popular2.png', name: 'Sample Destination' },
    title: 'Sample Destination',
    subtitle: 'Sample Subtitle',
  },
  {
    image: { url: '/travel/popular1.png', name: 'Sample Destination' },
    title: 'Sample Destination',
    subtitle: 'Sample Subtitle',
  },
  {
    image: { url: '/travel/popular2.png', name: 'Sample Destination' },
    title: 'Sample Destination',
    subtitle: 'Sample Subtitle',
  },
];

const ScrollContainer = styled.div`
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

function TravelPopularDestination({ title, destinations = SAMPLE_DESTINATIONS }: TravelPopularDestinationProps) {
  const {
    connectors: { connect },
    id,
  } = useNode();

  return (
    <div ref={connect as React.LegacyRef<HTMLDivElement>} id={id} className="w-full py-[60px] px-4 md:px-10 lg:px-36">
      <div className="mb-8">
        <h2 className="text-3xl font-bold text-[#183B56]">
          {title}
        </h2>
      </div>

      <ScrollContainer className="flex overflow-x-auto gap-6 snap-x snap-mandatory">
        {destinations.map((destination, index) => (
          <div 
            key={index} 
            className="relative group overflow-hidden rounded-lg flex-shrink-0 w-[265px] md:w-[540px] snap-start"
          >
            <div className="aspect-w-16 aspect-h-9">
              <img
                src={destination.image.url}
                alt={destination.image.name}
                className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-110"
              />
            </div>
            <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent">
              <div className="absolute bottom-6 left-6">
                <h3 className="text-2xl font-bold mb-1 text-white pr-1 line-clamp-2">{destination.title}</h3>
                <p className="text-sm text-white/90 text-white pr-1 line-clamp-2">{destination.subtitle}</p>
              </div>
            </div>
          </div>
        ))}
      </ScrollContainer>
    </div>
  );
}

TravelPopularDestination.defaultProps = {
  title: 'Popular Destinations',
  destinations: SAMPLE_DESTINATIONS,
};

TravelPopularDestination.craft = {
  props: TravelPopularDestination.defaultProps,
  related: {
    settings: TravelPopuplarDestinationSetting,
  },
};
export default TravelPopularDestination;
