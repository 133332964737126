import React, { useEffect } from 'react';
import { useNode } from '@craftjs/core';
import { Input, Button, Flex } from 'antd';
import AssetUpload from '@/components/AssetUpload';

type CommunitySectionProps = {
  heading: string;
  description: string;

  images: { url: string; name: string }[];
  themeColor?: string;
};

const CommunitySection = ({
  heading,
  description,

  images,
  themeColor,
}: CommunitySectionProps) => {
  const {
    connectors: { connect },
    id,
  } = useNode();

  return (
    <div
      className="relative mx-auto px-4 grid grid-cols-1 md:grid-cols-2 gap-8 overflow-hidden"
      ref={connect as React.LegacyRef<HTMLDivElement>}
      id={id}
    >
      <div className="flex  h-[75vh] gap-8 overflow-hidden order-2 md:order-1">
        {images.map((image, index) => (
          <div
            key={index}
            className="relative w-[200px] h-full overflow-hidden"
          >
            <div
              className="absolute flex flex-col gap-6"
              style={{
                animation: `${index % 2 === 0 ? 'scroll-down' : 'scroll-up'} 20s linear infinite`,
              }}
            >
              <style>
                {`
          @keyframes scroll-down {
            0% {
              transform: translateY(0);
            }
            100% {
              transform: translateY(-100%);
            }
          }

          @keyframes scroll-up {
            0% {
              transform: translateY(-100%);
            }
            100% {
              transform: translateY(0);
            }
          }
        `}
              </style>

              {[...Array(8)].map((_, i) => (
                <img
                  key={`img-${index}-${i}`}
                  src={i % 2 === 0 ? images[0]?.url : images[1]?.url}
                  alt={`Image ${i}`}
                  className="w-[200px] h-auto"
                />
              ))}
            </div>
          </div>
        ))}
      </div>

      <div className="flex flex-col justify-center w-full px-4 order-1 md:order-2">
        <h2
          className="text-3xl md:text-5xl font-sans font-extrabold mb-6"
          style={{ color: themeColor }}
        >
          {heading}
        </h2>

        <p className="text-gray-700 text-lg leading-relaxed">{description}</p>
      </div>
    </div>
  );
};

CommunitySection.defaultProps = {
  heading: 'Connecting The Community',
  themeColor: '#057E4B',
  description:
    'Building an urban area goes hand in hand with developing a civilized residential community. Therefore, Starlake - New Green City aims to create a lifestyle rooted in community values—an ideal place to live that not only offers comprehensive amenities but also promotes social connection, creating a cultured living environment that values spiritual well-being. Every resident here will feel proud to be part of Starlake - New Green City community.',

  images: [
    { url: '/real-estate/community-1.png', name: 'Image 1' },
    { url: '/real-estate/community-2.png', name: 'Image 2' },
  ],
};

const CommunitySectionSettings = () => {
  const {
    actions: { setProp },
    heading,
    description,

    images,
  } = useNode((node) => ({
    heading: node.data.props.heading,
    description: node.data.props.description,

    images: node.data.props.images,
  }));

  return (
    <div>
      <div className="mb-5">
        <label className="block mb-2 font-bold text-gray-700">Heading</label>
        <Input
          value={heading}
          onChange={(e) =>
            setProp(
              (props: { heading: string }) => (props.heading = e.target.value)
            )
          }
        />
      </div>

      <div className="mb-5">
        <label className="block mb-2 font-bold text-gray-700">
          Description
        </label>
        <Input.TextArea
          autoSize={{ minRows: 5, maxRows: 10 }}
          value={description}
          onChange={(e) =>
            setProp(
              (props: { description: string }) =>
                (props.description = e.target.value)
            )
          }
        />
      </div>

      {/* Animation Duration */}

      {images.map((image, index) => (
        <div key={index} className="mb-5">
          <label className="block mb-2 font-bold text-gray-700">{`Image ${index + 1}`}</label>
          <Flex className="w-full" align="center" justify="space-between">
            <span>{image.name}</span>
            <AssetUpload
              onSelect={(file) => {
                setProp(
                  (props: { images: { url: string; name: string }[] }) => {
                    props.images[index] = {
                      url: file.url,
                      name: file.name,
                    };
                  }
                );
              }}
            >
              <Button type="link" className="p-0">
                CHANGE
              </Button>
            </AssetUpload>
          </Flex>
        </div>
      ))}
    </div>
  );
};

CommunitySection.craft = {
  props: CommunitySection.defaultProps,
  related: {
    settings: CommunitySectionSettings,
  },
  displayName: 'Community Section',
};

export default CommunitySection;
