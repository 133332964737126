import { useNode } from '@craftjs/core';
import React from 'react';
import { FaQuoteLeft } from 'react-icons/fa';

type TestimonialProps = {
  themeColor?: string;
};

const Testimonial: React.FC = ({ themeColor }: TestimonialProps) => {
  const {
    connectors: { connect },
    id,
  } = useNode();
  console.log({ themeColor });
  return (
    <div
      id={id}
      style={{
        background: themeColor
          ? `${themeColor.replace(')', ', 10%)')}`
          : undefined,
      }}
      className="flex flex-col md:flex-row items-center justify-center w-full h-auto p-6 md:py-20 md:px-2 bg-[#E6F4FF] "
    >
      <div className="md:w-1/3 w-full p-6 text-center md:text-left">
        <FaQuoteLeft className={`text-[${themeColor}] text-3xl mb-4`} />
        <p className="text-[#0F172A] text-2xl mb-4">
          With GTG CRM's seamless integrations and user-friendly platform, our
          sales and support teams work more efficiently. We've seen a
          significant improvement in customer satisfaction since making the
          switch.
        </p>
        <p className="text-gray-500 font-medium">
          Christine M., Marketing Director
        </p>
      </div>

      {/* Right side: Responsive Image */}
      <div className="md:w-1/2 w-full p-6 flex justify-center">
        <div className="relative aspect-w-1 aspect-h-1 max-w-full rounded-xl overflow-hidden ">
          <img
            src="/testimonial.png"
            alt="Christine M."
            className="w-full h-full object-cover"
          />
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
