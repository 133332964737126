import AssetUpload from '@/components/AssetUpload';
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { useNode } from '@craftjs/core';
import { Button, Flex, Select } from 'antd';
import { StyledCollapse } from '../../travelStyles';

const SOCIAL_MEDIA_OPTIONS = [
  { label: 'Google', value: '/travel/social-google.svg' },
  { label: 'Instagram', value: '/travel/social-instagram.svg' },
  { label: 'LinkedIn', value: '/travel/social-linkin.svg' },
];

interface SocialMedia {
  icon: string;
  link: string;
}

const SocialMediaItem = ({
  icon,
  link,
  onIconChange,
  onLinkChange,
}: {
  icon: string;
  link: string;
  onIconChange: (value: string) => void;
  onLinkChange: (value: string) => void;
}) => (
  <Flex vertical gap="middle">
    <Select
      value={icon}
      onChange={onIconChange}
      options={SOCIAL_MEDIA_OPTIONS}
      className="w-full"
    />

    <textarea
      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
      placeholder="URL (e.g., https://facebook.com/...)"
      value={link}
      onChange={(e) => onLinkChange(e.target.value)}
    />
  </Flex>
);

const TravelFooterSetting = () => {
  const { actions: { setProp }, logo, leftContent, rightContent, socialMedia = [] } = useNode((node) => ({
    logo: node.data.props.logo,
    leftContent: node.data.props.leftContent,
    rightContent: node.data.props.rightContent,
    socialMedia: node.data.props.socialMedia || []
  }));

  const handleAddSocialMedia = () => {
    setProp((props: { socialMedia: SocialMedia[] }) => {
      const newSocialMedia = [...(props.socialMedia || []), {
        icon: SOCIAL_MEDIA_OPTIONS[0].value,
        link: ''
      }];
      props.socialMedia = newSocialMedia;
    }, 1000);
  };

  const handleRemoveSocialMedia = (index: number) => {
    setProp((props: { socialMedia: SocialMedia[] }) => {
      const newSocialMedia = [...(props.socialMedia || [])];
      newSocialMedia.splice(index, 1);
      props.socialMedia = newSocialMedia;
    }, 1000);
  };

  const handleSocialMediaChange = (index: number, field: keyof SocialMedia, value: string) => {
    setProp((props: { socialMedia: SocialMedia[] }) => {
      const newSocialMedia = [...(props.socialMedia || [])];
      newSocialMedia[index][field] = value;
      props.socialMedia = newSocialMedia;
    }, 1000);
  };

  return (
    <Flex vertical>
      {/* Logo */}
      <div className="mb-5">
        <label className="block mb-2 font-bold text-gray-700">Logo</label>
        <Flex className="w-full" align="center" justify="space-between">
          <span>{logo?.name}</span>
          <AssetUpload
            onSelect={(file) => {
              setProp(
                (props: { logo: { url: string; name: string } }) =>
                  (props.logo = { url: file.url, name: file.name }),
                1000
              );
            }}
          >
            <Button type="link" className="p-0">
              CHANGE
            </Button>
          </AssetUpload>
        </Flex>
      </div>

      {/* Title */}
      <div className="mb-5">
        <label className="block mb-2 font-bold text-gray-700">Description</label>
        <textarea
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          value={leftContent}
          onChange={(e) =>
            setProp((props: { leftContent: string }) => (props.leftContent = e.target.value))
          }
        />
      </div>

      {/* Subtitle */}
      <div className="mb-5">
        <label className="block mb-2 font-bold text-gray-700">Copyright</label>
        <input
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          value={rightContent}
          onChange={(e) =>
            setProp((props: { rightContent: string }) => (props.rightContent = e.target.value))
          }
        />
      </div>

      {/* Social Media Section */}
      <div className="mb-5">
        <label className="block font-bold text-gray-700 mb-2">Social Media Links</label>
        <StyledCollapse defaultActiveKey={['0']}
          className="mb-2"
          items={socialMedia.map((social: SocialMedia, index: number) => ({
            key: index.toString(),
            label: (
              <Flex justify="space-between" align="center">
                <span className="font-semibold text-gray-600">Social Media {index + 1}</span>
                <Button
                  danger
                  type="link"
                  icon={<DeleteOutlined />}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemoveSocialMedia(index);
                  }}
                />
              </Flex>
            ),
            children: (
              <SocialMediaItem
                icon={social.icon}
                link={social.link}
                onIconChange={(value) => handleSocialMediaChange(index, 'icon', value)}
                onLinkChange={(value) => handleSocialMediaChange(index, 'link', value)}
              />
            ),
          }))}
        />

        <Button
          type="dashed"
          className='w-full'
          icon={<PlusOutlined />}
          onClick={handleAddSocialMedia}
        >
          Add Social Media
        </Button>
      </div>
    </Flex>
  );
};

export default TravelFooterSetting;
