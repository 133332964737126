
import AssetUpload from '@/components/AssetUpload';
import { useNode } from '@craftjs/core';
import { Button, Flex } from 'antd';

function TravelHeaderSetting() {
  const {
    actions: { setProp },
    backgroundImage,
    logo,
    title,
    subtitle,
    buttonLabel,
    buttonLink,
  } = useNode((node) => ({
    backgroundImage: node.data.props.backgroundImage,
    logo: node.data.props.logo,
    title: node.data.props.title,
    subtitle: node.data.props.subtitle,
    buttonLabel: node.data.props.buttonLabel,
    buttonLink: node.data.props.buttonLink,
  }));

  return (
    <div>
      <div className="mb-5">
        <label className="block mb-2 font-bold text-gray-700">
          Background Image
        </label>
        <Flex className="w-full" align="center" justify="space-between">
          <span>{backgroundImage?.name}</span>
          <AssetUpload
            onSelect={(file) => {
              setProp(
                (props: { backgroundImage: { url: string; name: string } }) =>
                  (props.backgroundImage = {
                    url: file.url,
                    name: file.name,
                  }),
                1000
              );
            }}
          >
            <Button type="link" className="p-0">
              CHANGE
            </Button>
          </AssetUpload>
        </Flex>
      </div>

      {/* Logo */}
      <div className="mb-5">
        <label className="block mb-2 font-bold text-gray-700">Logo</label>
        <Flex className="w-full" align="center" justify="space-between">
          <span>{logo?.name}</span>
          <AssetUpload
            onSelect={(file) => {
              setProp(
                (props: { logo: { url: string; name: string } }) =>
                  (props.logo = { url: file.url, name: file.name }),
                1000
              );
            }}
          >
            <Button type="link" className="p-0">
              CHANGE
            </Button>
          </AssetUpload>
        </Flex>
      </div>

      {/* Title */}
      <div className="mb-5">
        <label className="block mb-2 font-bold text-gray-700">Title</label>
        <textarea
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          value={title}
          onChange={(e) =>
            setProp(
              (props: { title: string }) => (props.title = e.target.value)
            )
          }
        />
      </div>

      {/* Subtitle */}
      <div className="mb-5">
        <label className="block mb-2 font-bold text-gray-700">Subtitle</label>
        <textarea
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          value={subtitle}
          onChange={(e) =>
            setProp(
              (props: { subtitle: string }) => (props.subtitle = e.target.value)
            )
          }
        />
      </div>

      <div className="mb-5">
        <label className="block mb-2 font-bold text-gray-700">
          Button Label
        </label>
        <input
          type="text"
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          value={buttonLabel}
          onChange={(e) =>
            setProp(
              (props: { buttonLabel: string }) =>
                (props.buttonLabel = e.target.value)
            )
          }
        />
      </div>

      <div className="mb-5">
        <label className="block mb-2 font-bold text-gray-700">
          Button Link
        </label>
        <input
          type="text"
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          value={buttonLink}
          onChange={(e) =>
            setProp(
              (props: { buttonLink: string }) =>
                (props.buttonLink = e.target.value)
            )
          }
        />
      </div>

    </div>
  );
}

export default TravelHeaderSetting;
