import React from 'react';
import { TravelTourProps } from '../types';
import { useNode } from '@craftjs/core';
import TravelTourSetting from './settings/TravelTourSetting';

function TravelTour({ subtitle, title, firstContent, secondContent, themeColor }: TravelTourProps) {
  const {
    connectors: { connect },
    id,
  } = useNode();
  
  return (
    <div id={id} ref={connect as React.LegacyRef<HTMLDivElement>} className="px-4 md:px-10 lg:px-36 py-[50px] md:py-[100px] flex flex-col items-center justify-center">
      {/* Top Section */}
      <div className="text-center mb-8">
        <p className="text-sm font-semibold mb-4" style={{ color: themeColor }}>{subtitle}</p>
        <h2 className="text-[#183B56] text-3xl font-bold mb-2 max-w-[785px]">
          {title}
        </h2>
      </div>

      {/* Middle Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 w-full mb-8 items-center">
        <div className="order-2 md:order-1 flex flex-col">
          <h3 className="text-[#183B56] text-2xl font-bold mb-4">
            {firstContent.title}
          </h3>
          <p className="text-[#5A7184] mb-4">
            {firstContent.description}
          </p>
          <a href={firstContent.buttonBook.link} className="w-[200px] h-[48px] text-center leading-7 border-2 border-[#00B4B4] text-[#00B4B4] py-2 px-4 rounded-full font-bold">
            {firstContent.buttonBook.label}
          </a>
        </div>
        <img
          src={firstContent.image.url}
          alt={firstContent.image.name}
          className="w-full h-full object-cover rounded-xl order-1 md:order-2"
        />
      </div>

      {/* Bottom Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 w-full pt-[48px] items-center">
        <img
          src={secondContent.image.url}
          alt={secondContent.image.name}
          className="w-full h-full object-cover rounded-xl"
        />
        <div>
          <h3 className="text-[#183B56] text-2xl font-bold mb-4">
            {secondContent.title}
          </h3>
          <p className="text-[#5A7184] mb-4">
            {secondContent.description}
          </p>
          <ul className="text-[#183B56] space-y-2">
            {secondContent.listItem.map((item, index) => (
              <li key={index} className="flex items-center">
                <svg
                  className="w-5 h-5 text-[#00B4B4] mr-2"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                {item}
              </li>
            ))}
          </ul>
        </div>
      </div>

    </div>
  );
}

TravelTour.defaultProps = {
  themeColor: '#00B4B4',
  subtitle: 'Lorem ipsum',
  title: 'Sed sed massa augue. Proin efficitur diam sed ex pretium facilisis.',
  firstContent: {
    title: 'Nam nec malesuada libero, ac eleifend sapien',
    image: { url: '/travel/card-image-1.png', name: 'Sample Image' },
    description: 'Integer faucibus mi sed nibh blandit, a tempor nisl accumsan. Fusce scelerisque purus ac neque molestie, sed tincidunt risus sagittis. Fusce pulvinar at tortor ut dignissim.',
    buttonBook: { label: 'Book Now', link: '#' },
  },
  secondContent: {
    title: 'Vestibulum ut odio eget lacus dapibus varius vitae sed augue.',
    image: { url: '/travel/card-image-2.png', name: 'Sample Image' },
    description: 'Nam hendrerit purus nec aliquet aliquam. Vestibulum vulputate tincidunt elit, et facilisis dui dapibus vitae.',
    listItem: ['Sed aliquet dapibus faucibus', 'Sed aliquet dapibus faucibus', 'Sed aliquet dapibus faucibus', 'Sed aliquet dapibus faucibus', 'Sed aliquet dapibus faucibus'],
  },
};

TravelTour.craft = {
  props: TravelTour.defaultProps,
  related: {
    settings: TravelTourSetting,
  },
  displayName: 'Tour',
};

export default TravelTour;
