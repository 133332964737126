import { Editor, Element, Frame } from '@craftjs/core';
import React from 'react';

import * as FormBuilder from './components/form-builder';
import { FormBox } from '.';
import LoadPage from './components/LoadPage';
import CrmTheme from './themes/theme-1';
import CosmeticTheme from './themes/theme-2';
import RealEstateTheme from './themes/theme-3';
import TravelTheme from './themes/theme-4';

const Container = ({ children }: any) => {
  return <div className="h-full">{children}</div>;
};

// TODO: mapping with BE
const themeMapping = {
  ['theme-1']: CrmTheme,
  ['theme-2']: CosmeticTheme,
  // ['gtg-real-estate']: RealEstateTheme,
  ['theme-4']: TravelTheme,
};

// Handle for N theme

const themeCode = process.env.REACT_APP_THEME || 'theme-1';
const themeUsing = themeMapping[themeCode] || CrmTheme;
console.log(themeUsing);
console.log(themeCode);
const LandingPage: React.FC = () => {
  return (
    <div className="min-h-screen relative">
      <Editor
        resolver={{
          ...RealEstateTheme,
          ...themeUsing,
          ...FormBuilder,
          Container,
          LoadPage,
          FormBox,
        }}
      >
        <Frame>
          <Element canvas id="root" is={Container}>
            <LoadPage />
          </Element>
        </Frame>
      </Editor>
    </div>
  );
};

export default LandingPage;
