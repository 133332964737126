import { useNode } from '@craftjs/core';
import React, { useState, useEffect } from 'react';
import { TravelTestimonialProps } from '../types';
import TravelTestimonialSetting from './settings/TravelTestimonialSetting';

const testimonials: TravelTestimonialProps['listItem'] = [
  {
    content: "Quisque metus magna, lobortis sodales nulla at, vulputate pretium diam. Ut sit amet faucibus lorem. Suspendisse congue nunc vel dolor finibus, vel hendrerit sapien fringilla. Proin hendrerit tristique augue, et pulvinar diam ultrices rutrum.",
    author: "Robert. D",
    jobTitle: "Sale Manager at Proton Asia",
    avatarUrl: { url: "/travel/testimonial.png", name: "testimonial" },
  },
  {
    content: "B metus magna, lobortis sodales nulla at, vulputate pretium diam. Ut sit amet faucibus lorem. Suspendisse congue nunc vel dolor finibus, vel hendrerit sapien fringilla. Proin hendrerit tristique augue, et pulvinar diam ultrices rutrum.",
    author: "Robert. B",
    jobTitle: "Sale Manager at Proton Asia",
    avatarUrl: { url: "/travel/testimonial.png", name: "testimonial" },
  },
];

function TravelTestimonial({ themeColor = "#00AFAF", listItem, intervalTimeSlide}: TravelTestimonialProps) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const {
    connectors: { connect },
    id,
  } = useNode();
  const currentTestimonial = listItem[currentIndex];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => 
        prevIndex === listItem.length - 1 ? 0 : prevIndex + 1
      );
    }, intervalTimeSlide);

    return () => clearInterval(interval);
  }, [listItem.length, intervalTimeSlide]);

  return (
    <div
      id={id}
      ref={connect as React.LegacyRef<HTMLDivElement>}
      className="flex flex-col lg:flex-row items-center justify-center w-full h-auto px-4 md:px-10 lg:px-36 pb-[50px] md:pb-[100px]"
    >
      {/* Left side content */}
      <div className="lg:w-2/3 w-full p-6 pl-0 text-center md:text-left order-2 lg:order-1">
        <p className="text-[#0F172A] text-xl mb-6 leading-relaxed">
          {currentTestimonial.content}
        </p>
        <div className="mb-8">
          <p className="font-semibold" style={{ color: themeColor }}>{currentTestimonial.author}</p>
          <p className="text-gray-500">{currentTestimonial.jobTitle}</p>
        </div>

        {/* Navigation dots */}
        <div className="flex gap-2 justify-center lg:justify-start order-2 lg:order-1">
          {listItem.map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentIndex(index)}
              className="h-2 rounded-full transition-all w-2"
              style={{
                background: index === currentIndex ? themeColor : "#E2E8F0",
              }}
            />
          ))}
        </div>
      </div>

      {/* Right side: Circular Image */}
      <div className="lg:w-1/3 w-full pr-0 flex justify-center lg:justify-end order-1 lg:order-2">
        <div className="relative w-80 h-80 rounded-full overflow-hidden">
          <img
            src={currentTestimonial.avatarUrl.url}
            alt="Testimonial"
            className="w-full h-full object-cover"
          />
        </div>
      </div>
    </div>
  );
}

TravelTestimonial.defaultProps = {
  listItem: testimonials,
  intervalTimeSlide: 3000
};

TravelTestimonial.craft = {
  props: TravelTestimonial.defaultProps,
  related: {
    settings: TravelTestimonialSetting,
  },
};

export default TravelTestimonial;
