import React from 'react';
import { Button, Flex } from 'antd';
import { useNode } from '@craftjs/core';
import AssetUpload from '@/components/AssetUpload';

type HeroSectionREProps = {
  backgroundImage: { url: string; name: string };
  logo: { url: string; name: string };
  title: string;
  subtitle: string;
  buttonLabel: string;
  buttonLink: string;
  themeColor: string;
  textPosition: 'left' | 'right' | 'center';
  swapLogoAndButton?: boolean;
  hideLogo?: boolean;
};

function HeroSectionRE({
  backgroundImage,
  logo,
  title,
  subtitle,
  buttonLabel,
  buttonLink,
  themeColor,
  textPosition,
  swapLogoAndButton,
  hideLogo,
}: HeroSectionREProps) {
  const {
    connectors: { connect },
    id,
  } = useNode();

  return (
    <div
      className="relative px-11 md:px-36 bg-cover bg-no-repeat bg-center h-[60vh] md:h-[90vh] z-10"
      style={{
        backgroundImage: `url(${backgroundImage.url})`,
      }}
      ref={connect as React.LegacyRef<HTMLDivElement>}
      id={id}
    >
      <div className="w-full flex justify-between items-center pt-10">
        <div
          className={`flex items-center ${swapLogoAndButton ? 'order-2' : 'order-1'}`}
          style={{ visibility: hideLogo ? 'hidden' : 'visible' }}
        >
          <img className="max-h-28 max-w-32" src={logo.url} alt={logo.name} />
        </div>

        <div className={`${swapLogoAndButton ? 'order-1' : 'order-2'}`}>
          <button
            className="border-none font-bold py-2 px-8 rounded-full shadow-lg"
            onClick={() => (window.location.href = buttonLink)}
            style={{
              backgroundColor: themeColor,
              color: '#FFFFFF',
            }}
          >
            {buttonLabel}
          </button>
        </div>
      </div>

      <div
        className={`h-full flex ${
          textPosition === 'center'
            ? 'justify-center text-center'
            : textPosition === 'right'
              ? 'justify-end text-right'
              : 'justify-start text-left'
        } items-center`}
      >
        <div className="text-[#FFFFFF] max-w-md font-sans">
          <h1 className="text-4xl md:text-6xl font-bold mb-4">{title}</h1>
          <p className="text-lg md:text-xl font-thin font-sans mb-8">
            {subtitle}
          </p>
        </div>
      </div>
    </div>
  );
}

HeroSectionRE.defaultProps = {
  backgroundImage: {
    url: '/real-estate/real-estate-bg.png',
    name: 'Background Image',
  },
  logo: { url: '/real-estate/logo-gtg-green.svg', name: 'Logo' },
  title: 'Starlake  New Green City',
  subtitle: 'A place where value elevates prestige',
  buttonLabel: 'Contact',
  buttonLink: 'https://app.gtgcrm.com/',
  themeColor: '#057E4B',
  textPosition: 'left',
  swapLogoAndButton: false,
  hideLogo: false,
};

import {
  AiOutlineAlignLeft,
  AiOutlineAlignCenter,
  AiOutlineAlignRight,
} from 'react-icons/ai';
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  SwapOutlined,
} from '@ant-design/icons';

function HeroSectionRESettings() {
  const {
    actions: { setProp },
    backgroundImage,
    logo,
    title,
    subtitle,
    buttonLabel,
    buttonLink,
    textPosition,
    swapLogoAndButton,
    hideLogo,
  } = useNode((node) => ({
    backgroundImage: node.data.props.backgroundImage,
    logo: node.data.props.logo,
    title: node.data.props.title,
    subtitle: node.data.props.subtitle,
    buttonLabel: node.data.props.buttonLabel,
    buttonLink: node.data.props.buttonLink,
    textPosition: node.data.props.textPosition,
    swapLogoAndButton: node.data.props.swapLogoAndButton,
    hideLogo: node.data.props.hideLogo,
  }));

  return (
    <div>
      <div className="mb-5">
        <label className="block mb-2 font-bold text-gray-700">
          Background Image
        </label>
        <Flex className="w-full" align="center" justify="space-between">
          <span>{backgroundImage?.name}</span>
          <AssetUpload
            onSelect={(file) => {
              setProp(
                (props: { backgroundImage: { url: string; name: string } }) =>
                  (props.backgroundImage = { url: file.url, name: file.name }),
                1000
              );
            }}
          >
            <Button type="link" className="p-0">
              CHANGE
            </Button>
          </AssetUpload>
        </Flex>
      </div>

      {/* Logo */}
      <div className="mb-5">
        <label className="block mb-2 font-bold text-gray-700">Logo</label>
        <Flex className="w-full" align="center" justify="space-between">
          <span>{logo?.name}</span>
          <AssetUpload
            onSelect={(file) => {
              setProp(
                (props: { logo: { url: string; name: string } }) =>
                  (props.logo = { url: file.url, name: file.name }),
                1000
              );
            }}
          >
            <Button type="link" className="p-0">
              CHANGE
            </Button>
          </AssetUpload>
          <button
            className={`p-2 border ${
              hideLogo ? 'border-blue-500' : 'border-gray-300'
            } rounded-md flex items-center justify-center`}
            onClick={() =>
              setProp(
                (props: { hideLogo: boolean }) => (props.hideLogo = !hideLogo)
              )
            }
          >
            {hideLogo ? (
              <EyeOutlined className="text-xl text-blue-500" />
            ) : (
              <EyeInvisibleOutlined className="text-xl text-gray-500" />
            )}
          </button>
        </Flex>
      </div>

      {/* Subtitle */}
      <div className="mb-5">
        <label className="block mb-2 font-bold text-gray-700">Subtitle</label>
        <textarea
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          value={subtitle}
          onChange={(e) =>
            setProp(
              (props: { subtitle: string }) => (props.subtitle = e.target.value)
            )
          }
        />
      </div>

      {/* Button Label */}
      <div className="mb-5">
        <label className="block mb-2 font-bold text-gray-700">
          Button Label
        </label>
        <input
          type="text"
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          value={buttonLabel}
          onChange={(e) =>
            setProp(
              (props: { buttonLabel: string }) =>
                (props.buttonLabel = e.target.value)
            )
          }
        />
      </div>

      {/* Button Link */}
      <div className="mb-5">
        <label className="block mb-2 font-bold text-gray-700">
          Button Link
        </label>
        <input
          type="text"
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          value={buttonLink}
          onChange={(e) =>
            setProp(
              (props: { buttonLink: string }) =>
                (props.buttonLink = e.target.value)
            )
          }
        />
      </div>

      <div className="mb-5">
        <label className="block mb-2 font-bold text-gray-700">
          Reverse Header Items
        </label>
        <button
          className={`p-2 border ${
            swapLogoAndButton ? 'border-blue-500' : 'border-gray-300'
          } rounded-md flex items-center justify-center`}
          onClick={() =>
            setProp(
              (props: { swapLogoAndButton: boolean }) =>
                (props.swapLogoAndButton = !swapLogoAndButton)
            )
          }
        >
          <SwapOutlined
            className={`text-2xl ${
              swapLogoAndButton ? 'text-blue-500' : 'text-gray-500'
            }`}
          />
        </button>
      </div>

      {/* Text Position */}
      <div className="mb-5">
        <label className="block mb-2 font-bold text-gray-700">
          Text Position
        </label>
        <div className="flex gap-4">
          <button
            className={`p-2 border ${
              textPosition === 'left' ? 'border-blue-500' : 'border-gray-300'
            } rounded-md`}
            onClick={() =>
              setProp(
                (props: { textPosition: 'left' | 'center' | 'right' }) =>
                  (props.textPosition = 'left')
              )
            }
          >
            <AiOutlineAlignLeft className="text-xl" />
          </button>
          <button
            className={`p-2 border ${
              textPosition === 'center' ? 'border-blue-500' : 'border-gray-300'
            } rounded-md`}
            onClick={() =>
              setProp(
                (props: { textPosition: 'left' | 'center' | 'right' }) =>
                  (props.textPosition = 'center')
              )
            }
          >
            <AiOutlineAlignCenter className="text-xl" />
          </button>
          <button
            className={`p-2 border ${
              textPosition === 'right' ? 'border-blue-500' : 'border-gray-300'
            } rounded-md`}
            onClick={() =>
              setProp(
                (props: { textPosition: 'left' | 'center' | 'right' }) =>
                  (props.textPosition = 'right')
              )
            }
          >
            <AiOutlineAlignRight className="text-xl" />
          </button>
        </div>
      </div>
    </div>
  );
}

HeroSectionRE.craft = {
  props: HeroSectionRE.defaultProps,
  related: {
    settings: HeroSectionRESettings,
  },
  displayName: 'Hero Section RE',
};

export default HeroSectionRE;
