import { GTGSIcon } from '@/components/GTGS';
import { useNode } from '@craftjs/core';
import React, { TouchEvent, useRef, useState } from 'react';
import { TravelBenefitProps } from '../types';
import TravelBenefitSetting from './settings/TravelBenefitSetting';

function TravelBenefit({ title, description, listItem, themeColor }: TravelBenefitProps) {
  const {
    connectors: { connect },
    id,
  } = useNode();
  const [activeSlide, setActiveSlide] = useState(0);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const sliderRef = useRef<HTMLDivElement>(null);

  const handleTouchStart = (e: TouchEvent) => {
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e: TouchEvent) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (!touchStart || !touchEnd) return;

    const distance = touchStart - touchEnd;
    const minSwipeDistance = 50;

    if (Math.abs(distance) < minSwipeDistance) return;

    if (distance > 0 && activeSlide < listItem.length - 1) {
      setActiveSlide(prev => prev + 1);
    }

    if (distance < 0 && activeSlide > 0) {
      setActiveSlide(prev => prev - 1);
    }

    setTouchStart(0);
    setTouchEnd(0);
  };

  return (
    <div
      id={id}
      ref={connect as React.Ref<HTMLDivElement>}
      className="relative px-4 md:px-10 lg:px-36 py-[50px] md:py-[100px] bg-cover bg-no-repeat bg-center py-20"
    >
      <div className="max-w-[700px] mx-auto">
        <h2 className="text-4xl font-bold text-center mb-4 text-[#183B56]">{title}</h2>
        <p className="text-center text-[#5A7184] mb-16 max-w-2xl mx-auto">
          {description}
        </p>
      </div>

      <div className="block md:hidden">
        <div className="overflow-hidden">
          <div
            ref={sliderRef}
            className="flex transition-transform duration-300 ease-in-out"
            style={{ transform: `translateX(-${activeSlide * 100}%)` }}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            {listItem.map((item, index) => (
              <div key={index} className="w-full flex-shrink-0 px-4">
                <div className="bg-white p-8 border border-[#E5EAF4] rounded-lg shadow-sm text-center">
                  <div className="w-16 h-16 mx-auto mb-6 flex items-center justify-center bg-teal-50 rounded-2xl">
                    <GTGSIcon name={item.iconName} size={40} fill={themeColor}className="text-teal-500" />
                  </div>
                  <h3 className="text-[#183B56] text-xl font-semibold mb-3">{item.title}</h3>
                  <p className="text-[#5A7184] text-sm">
                    {item.content}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="flex justify-center gap-2 mt-6">
          {listItem.map((_, index) => (
            <button
              key={index}
              className={`w-2 h-2 rounded-full ${index === activeSlide ? 'bg-teal-500' : 'bg-gray-300'
                }`}
              onClick={() => setActiveSlide(index)}
            />
          ))}
        </div>
      </div>

      <div className="hidden md:grid md:grid-cols-2 lg:grid-cols-4 gap-8">
        {listItem.map((item, index) => (
          <div key={index} className="bg-white p-8 border border-[#E5EAF4] rounded-lg shadow-sm text-center">
            <div className="w-16 h-16 mx-auto mb-6 flex items-center justify-center bg-teal-50 rounded-2xl">
              <GTGSIcon name={item.iconName} size={40} fill={themeColor} className="text-teal-500" />
            </div>
            <h3 className="text-xl font-semibold mb-3">{item.title}</h3>
            <p className="text-[#5A7184] text-sm">
              {item.content}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

TravelBenefit.defaultProps = {
  themeColor: '#00AFAF',
  title: 'Aenean et pretium mi',
  description: 'Nullam ac mauris nec velit venenatis lobortis nec et lectus. Cras pretium, arcu ut tristique auctor, justo nibh venenatis lectus, sed dignissim ante nisl nec erat.',
  listItem: [
    {
      iconName: "master-card",
      title: "Innovative Solutions",
      content: "Transforming ideas into reality with cutting-edge technology"
    },
    {
      iconName: "hourglass-1",
      title: "Growth Strategy",
      content: "Accelerate your business with data-driven decisions"
    },
    {
      iconName: "atm",
      title: "Team Collaboration",
      content: "Building success through effective teamwork"
    },
    {
      iconName: "bicycle",
      title: "Custom Solutions",
      content: "Tailored approaches to meet your unique needs"
    },
    {
      iconName: "bullseye",
      title: "Fast Delivery",
      content: "Quick and efficient implementation of solutions"
    },
    {
      iconName: "cloud-fog",
      title: "Secure Platform",
      content: "Your data safety is our top priority"
    },
    {
      iconName: "contacts",
      title: "Customer Focus",
      content: "Dedicated to exceeding your expectations"
    },
    {
      iconName: "ev-front",
      title: "Quality Assured",
      content: "Committed to delivering excellence in every project"
    }
  ],
};

TravelBenefit.craft = {
  props: TravelBenefit.defaultProps,
  related: {
    settings: TravelBenefitSetting,
  },
};

export default TravelBenefit;
