import React from 'react';
import {
  GoogleOutlined,
  InstagramOutlined,
  LinkedinOutlined,
  HeartFilled,
  EyeOutlined,
  EyeInvisibleOutlined,
} from '@ant-design/icons';
import { useNode } from '@craftjs/core';
import AssetUpload from '@/components/AssetUpload/AssetUpload';
import { Button } from 'antd';

type FooterREProps = {
  logo: { url: string; name: string };
  googleLink: string;
  instagramLink: string;
  linkedinLink: string;
  showLogo: boolean;
  footerText: string;
  craftedText: string;
};

const FooterRE = ({
  logo,
  googleLink,
  instagramLink,
  linkedinLink,
  showLogo,
  footerText,
  craftedText,
}: FooterREProps) => {
  const {
    connectors: { connect },
    id,
  } = useNode();

  return (
    <footer className="bg-[#0D1B2A] text-white py-20 px-20">
      <div
        ref={connect as React.LegacyRef<HTMLDivElement>}
        id={id}
        className="flex justify-between items-center container mx-auto"
      >
        <div>
          {showLogo && (
            <img className="max-h-28 max-w-32" src={logo?.url} alt="logo" />
          )}
          <p className="text-sm text-gray-400 mb-4 mt-4">{footerText}</p>
          <div className="flex gap-4">
            <a href={googleLink} target="_blank" rel="noopener noreferrer">
              <GoogleOutlined className="text-xl cursor-pointer hover:text-gray-300" />
            </a>
            <a href={instagramLink} target="_blank" rel="noopener noreferrer">
              <InstagramOutlined className="text-xl cursor-pointer hover:text-gray-300" />
            </a>
            <a href={linkedinLink} target="_blank" rel="noopener noreferrer">
              <LinkedinOutlined className="text-xl cursor-pointer hover:text-gray-300" />
            </a>
          </div>
        </div>

        <div className="flex flex-col items-center mt-10 text-gray-600">
          <button className="bg-blue-500 p-3 rounded-full mb-4">
            <HeartFilled className="text-[#fff] text-base" />
          </button>
          <p className="text-sm">{craftedText}</p>
        </div>
      </div>
    </footer>
  );
};

FooterRE.defaultProps = {
  logo: { url: '/real-estate/logo-gtg-green.svg', name: 'Logo' },
  googleLink: 'https://google.com',
  instagramLink: 'https://instagram.com',
  linkedinLink: 'https://linkedin.com',
  showLogo: true,
  footerText: 'Build a modern and creative website with gtgsrm',
  craftedText: 'Copyright © 2024. Crafted by GTG.',
};

// Craft.js Settings
function FooterRESettings() {
  const {
    actions: { setProp },
    logo,
    googleLink,
    instagramLink,
    linkedinLink,
    showLogo,
    footerText,
    craftedText,
  } = useNode((node) => ({
    logo: node.data.props.logo,
    googleLink: node.data.props.googleLink,
    instagramLink: node.data.props.instagramLink,
    linkedinLink: node.data.props.linkedinLink,
    showLogo: node.data.props.showLogo,
    footerText: node.data.props.footerText,
    craftedText: node.data.props.craftedText,
  }));

  return (
    <div>
      {/* Logo Management */}
      <div className="mb-5">
        <label className="block mb-2 font-bold text-gray-700">Logo</label>
        <div className="flex items-center justify-between">
          <span>{logo?.name}</span>
          <AssetUpload
            onSelect={(file) => {
              setProp(
                (props: { logo: { url: string; name: string } }) =>
                  (props.logo = { url: file.url, name: file.name }),
                1000
              );
            }}
          >
            <Button type="link" className="p-0">
              CHANGE
            </Button>
          </AssetUpload>
          <button
            className={`p-2 border ${
              showLogo ? 'border-blue-500' : 'border-gray-300'
            } rounded-md flex items-center justify-center`}
            onClick={() =>
              setProp(
                (props: { showLogo: boolean }) => (props.showLogo = !showLogo)
              )
            }
          >
            {showLogo ? (
              <EyeOutlined className="text-xl text-blue-500" />
            ) : (
              <EyeInvisibleOutlined className="text-xl text-gray-500" />
            )}
          </button>
        </div>
      </div>

      {/* Links */}
      <div className="mb-5">
        <label className="block mb-2 font-bold text-gray-700">
          Google Link
        </label>
        <input
          type="text"
          className="w-full px-3 py-2 border border-gray-300 rounded-md"
          value={googleLink}
          onChange={(e) =>
            setProp(
              (props: { googleLink: string }) =>
                (props.googleLink = e.target.value)
            )
          }
        />
      </div>
      <div className="mb-5">
        <label className="block mb-2 font-bold text-gray-700">
          Instagram Link
        </label>
        <input
          type="text"
          className="w-full px-3 py-2 border border-gray-300 rounded-md"
          value={instagramLink}
          onChange={(e) =>
            setProp(
              (props: { instagramLink: string }) =>
                (props.instagramLink = e.target.value)
            )
          }
        />
      </div>
      <div className="mb-5">
        <label className="block mb-2 font-bold text-gray-700">
          LinkedIn Link
        </label>
        <input
          type="text"
          className="w-full px-3 py-2 border border-gray-300 rounded-md"
          value={linkedinLink}
          onChange={(e) =>
            setProp(
              (props: { linkedinLink: string }) =>
                (props.linkedinLink = e.target.value)
            )
          }
        />
      </div>

      {/* Editable Texts */}
      <div className="mb-5">
        <label className="block mb-2 font-bold text-gray-700">
          Footer Text
        </label>
        <input
          type="text"
          className="w-full px-3 py-2 border border-gray-300 rounded-md"
          value={footerText}
          onChange={(e) =>
            setProp(
              (props: { footerText: string }) =>
                (props.footerText = e.target.value)
            )
          }
        />
      </div>
      <div className="mb-5">
        <label className="block mb-2 font-bold text-gray-700">
          Crafted Text
        </label>
        <input
          type="text"
          className="w-full px-3 py-2 border border-gray-300 rounded-md"
          value={craftedText}
          onChange={(e) =>
            setProp(
              (props: { craftedText: string }) =>
                (props.craftedText = e.target.value)
            )
          }
        />
      </div>
    </div>
  );
}

FooterRE.craft = {
  props: FooterRE.defaultProps,
  related: {
    settings: FooterRESettings,
  },
  displayName: 'FooterRE',
};

export default FooterRE;
