export enum RequestStatus {
    MEMBER = "MEMBER",
    OWNER = "OWNER",
    INVITED = "INVITED",
    INACTIVE = "INACTIVE",
    NEED_SETUP = "NEED_SETUP",
    WS_TEMPORAL_DELETED = "WS_TEMPORAL_DELETED",
    WAIT_PAYMENT_PROCESS = "WAIT_PAYMENT_PROCESS"
  }


  export enum PaymentAction {
    UNKNOWN = "UNKNOWN", // Default value, indicating no specific action has been determined.
    OPEN_HOSTED_PAYMENT = "OPEN_HOSTED_PAYMENT", // Action to open a hosted payment page.
    DO_NOTHING = "DO_NOTHING", // Action to do nothing.
  }

  export interface IWorkspace {
    name?: string;
    avatarBase64?: string;
    ownerInfo?: {
      displayName?: string;
      position?: string;
      phone?: string;
      avatarBase64?: string;
    }
    createdRoles?: {
      role?: string;
      privileges?: string[];
      description?: string;
      editable?: boolean;
    }[];
    informUsers?: {
      email?: string;
      roles?: string[];
      position?: string;
      plan?: string;
    }[];
}

export interface IAccounts {
  firstName?: string;
  lastName?: string;
  email?: string;
  companyName?: string;
  website?: string;
  phone?: string;
  avatarUrl?: string;
  assetId?: string;
}
export interface IWorkspaceInfo {
        id: string;
        name: string;
        ownerId: string;
        participants: {
            id: string;
            displayName: string;
            avatarBase64: string;
            email: string;
            phone: string;
            roles: string[];
            position: string;
            lastActive: string;
        }[];
        totalMember?: number | string;
        createdAt: string;
        updatedAt: string;
        avatarBase64: string;
        status: string;
        requestStatus:  RequestStatus
    }
    
    export type IWorkSpaceTypeProps = "workspace" | "accounts"| "bill-payment";

    export enum Privileges {
      VIEW = 'VIEW',
      CREATE = 'CREATE',
      EDIT = 'EDIT',
      DELETE = 'DELETE',
    }
    
    export const PrivilegesName = {
      [Privileges.VIEW]: 'View',
      [Privileges.CREATE]: 'Create',
      [Privileges.EDIT]: 'Edit',
      [Privileges.DELETE]: 'Delete',
    };

    export type ActionTypes = 'edit' | 'delete';

