import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { useNode } from '@craftjs/core';
import React, { useState } from 'react';
import { TravelTipsProps } from '../types';
import TravelTipsSetting from './settings/TravelTipsSetting';


const SAMPLE_SLIDES = [
  {
    title: "Vestibulum euismod pellentesque lectus, pulvinar congue nibh vulputate a.",
    image: { url: "/travel/tips.png", name: "tips" }
  },
  {
    title: "Vestibulum euismod pellentesque lectus, pulvinar congue nibh vulputate a.",
    image: { url: "/travel/tips2.png", name: "tips2" }
  },
  {
    title: "Vestibulum euismod pellentesque lectus, pulvinar congue nibh vulputate a.",
    image: { url: "/travel/tips.png", name: "tips" }
  },
  {
    title: "Vestibulum euismod pellentesque lectus, pulvinar congue nibh vulputate a.",
    image: { url: "/travel/tips2.png", name: "tips2" }
  }, {
    title: "Vestibulum euismod pellentesque lectus, pulvinar congue nibh vulputate a.",
    image: { url: "/travel/tips.png", name: "tips" }
  },
  {
    title: "Vestibulum euismod pellentesque lectus, pulvinar congue nibh vulputate a.",
    image: { url: "/travel/tips2.png", name: "tips2" }
  }
]

function TravelTips({ themeColor, subtitle, title, description, listItem }: TravelTipsProps) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const {
    connectors: { connect },
    id,
  } = useNode();

  const arrowButtonBase = "w-12 h-12 rounded-full bg-white shadow-lg flex items-center justify-center z-10";
  const arrowButtonDesktop = `hidden md:flex ${arrowButtonBase} md:relative lg:absolute lg:top-1/2 lg:-translate-y-1/2`;

  const ArrowButton = ({ direction, className }: { direction: 'left' | 'right', className?: string }) => (
    <button
      key={`arrow-${direction}`}
      onClick={() => setCurrentSlide(prev =>
        direction === 'left'
          ? (prev > 0 ? prev - 1 : listItem.length - 1)
          : (prev + 1) % listItem.length
      )}
      className={`rounded-full bg-white shadow-lg flex items-center justify-center ${className} text-[#00AFAF]`}
    >
      {direction === 'left' ? <ArrowLeftOutlined width={24} height={24} /> : <ArrowRightOutlined width={24} height={24} />}
    </button>
  );

  return (
    <div
      id={id}
      ref={connect as React.LegacyRef<HTMLDivElement>}
      className="px-4 md:px-10 lg:px-36 py-[50px] md:py-[100px] flex flex-col items-center justify-center"
    >
      <div className="text-center mb-8 max-w-[785px]">
        <p className="text-sm font-semibold mb-4" style={{ color: themeColor }}>{subtitle}</p>
        <h2 className="text-[#0F172A] text-3xl font-bold mb-4 max-w-[785px]">
          {title}
        </h2>
        <p className="text-gray-600 mb-6">
          {description}
        </p>
      </div>

      <div className="relative flex flex-col md:flex-row items-center">
        <ArrowButton direction="left" className={`${arrowButtonDesktop} md:left-0 lg:left-[-24px]`} />

        <div className="flex-1 mx-0 md:mx-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {listItem.map((slide, index) => {
              // For md screens: show current and next slide
              const isVisibleMd = index === currentSlide || index === (currentSlide + 1) % listItem.length;
              // For mobile: show only current slide
              const isVisibleSm = index === currentSlide;

              return (
                <div
                  key={index}
                  className={`transition-opacity duration-500 relative
                    ${isVisibleSm ? 'opacity-100' : 'opacity-0 hidden md:block md:opacity-100'}
                    ${isVisibleMd ? 'md:opacity-100' : 'md:opacity-0 md:hidden'}
                  `}
                >
                  <img
                    src={slide.image.url}
                    alt={slide.title}
                    className="w-full h-[300px] md:h-[600px] object-cover rounded-lg"
                  />
                  <h3 className="absolute bottom-8 left-8 right-8 text-white text-2xl font-bold line-clamp-2">
                    {slide.title}
                  </h3>
                </div>
              );
            })}
          </div>
        </div>

        <ArrowButton direction="right" className={`${arrowButtonDesktop} md:right-0 lg:right-[-24px]`} />

        <div className="flex gap-4 mt-4 md:hidden">
          <ArrowButton direction="left" className={arrowButtonBase} />
          <ArrowButton direction="right" className={arrowButtonBase} />
        </div>
      </div>
    </div>
  );
}

TravelTips.defaultProps = {
  subtitle: 'Cras ultrices accumsan dapibus',
  title: 'Nam nec malesuada libero, ac eleifend sapien',
  description: 'Phasellus aliquam mattis lacus, a malesuada massa ultricies nec. Nam tempus justo ipsum, sed gravida turpis congue sit amet.',
  listItem: SAMPLE_SLIDES,
  themeColor: '#00B4B4',
};

TravelTips.craft = {
  props: TravelTips.defaultProps,
  related: {
    settings: TravelTipsSetting,
  },
};

export default TravelTips;
