import AssetUpload from "@/components/AssetUpload";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { useNode } from "@craftjs/core";
import { Button, Flex } from "antd";
import { StyledCollapse } from "../../travelStyles";

interface Destination {
    title: string;
    subtitle: string;
    image: {
        url: string;
        name: string;
    };
}

const PopularItem = ({ destination, index, updateDestination }) => {
    return (
        <Flex vertical gap="middle">
            <div>
                <label className="block font-bold text-gray-700">Title</label>
                <input
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Title"
                    value={destination.title}
                    onChange={(e) => updateDestination(index, 'title', e.target.value)}
                />
            </div>

            <div>
                <label className="block font-bold text-gray-700">Sub title</label>
                <textarea
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Subtitle"
                    value={destination.subtitle}
                    onChange={(e) => updateDestination(index, 'subtitle', e.target.value)}
                />
            </div>

            <Flex align="center" justify="space-between">
                <span>{destination.image?.name || 'No image selected'}</span>
                <AssetUpload
                    onSelect={(file) => {
                        updateDestination(index, 'image', {
                            url: file.url,
                            name: file.name
                        });
                    }}
                >
                    <Button type="link">
                        {destination.image?.url ? 'CHANGE' : 'SELECT'} IMAGE
                    </Button>
                </AssetUpload>
            </Flex>
        </Flex>
    );
};

const TravelPopuplarDestinationSetting = () => {
    const {
        actions: { setProp },
        destinations = []
    } = useNode((node) => ({
        destinations: node.data.props.destinations || []
    }));

    const handleAddDestination = () => {
        setProp((props: { destinations: Destination[] }) => {
            const newDestinations = [...(props.destinations || []), {
                title: "Sample Destination",
                subtitle: "Sample Subtitle",
                image: { url: "/images/IIIustrations/wellcome.svg", name: "wellcome" }
            }];
            props.destinations = newDestinations;
        }, 1000);
    };

    const handleRemoveDestination = (index: number) => {
        setProp((props: { destinations: Destination[] }) => {
            const newDestinations = [...(props.destinations || [])];
            newDestinations.splice(index, 1);
            props.destinations = newDestinations;
        }, 1000);
    };

    const updateDestination = (index: number, field: keyof Destination, value: any) => {
        setProp((props: { destinations: Destination[] }) => {
            const newDestinations = [...(props.destinations || [])];
            if (field === 'image') {
                newDestinations[index][field] = value;
            } else {
                newDestinations[index][field as 'title' | 'subTitle'] = value;
            }
            props.destinations = newDestinations;
        }, 1000);
    };

    return (
        <Flex vertical gap="middle">
            <div className="mb-5">
                <label className="block font-bold text-gray-700 mb-2">Destinations</label>

                <StyledCollapse
                    className="mb-2"
                    defaultActiveKey={['0']}
                    items={destinations.map((destination: Destination, index: number) => ({
                        key: index.toString(),
                        label: (
                            <Flex justify="space-between" align="center">
                                <span className="font-semibold text-gray-600">Destination {index + 1}</span>
                                <Button
                                    danger
                                    type="link"
                                    icon={<DeleteOutlined />}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveDestination(index);
                                    }}
                                />
                            </Flex>
                        ),
                        children: (
                            <PopularItem
                                destination={destination}
                                index={index}
                                updateDestination={updateDestination}
                            />
                        ),
                    }))}
                />

                <Button
                    className="w-full"
                    type="dashed"
                    icon={<PlusOutlined />}
                    onClick={handleAddDestination}
                >
                    Add Destination
                </Button>
            </div>
        </Flex>
    );
};

export default TravelPopuplarDestinationSetting;
