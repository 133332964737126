// Use ```ls ./resources | sed -e 's|^|"|g' -e 's|\",|",|g' | sort``` to get the list of icons
export const ICON_NAMES = [
  'master-card',
  'upload-image',
  'upload-file',
  'check-circle-2',
  'hourglass-1',
  'currency-dollar-circle',
  'user-share',
  'info-circle-red',
  'gmail-contact',
  'instagram-contact',
  'linkedIn-contact',
  'circle-timeline',
  'circle-timeline-1',
  'star-yellow',
  '!-lg',
  '_-lg',
  '0-circle',
  '0-circle-fill',
  '0-square',
  '0-square-fill',
  '1-circle',
  '1-circle-fill',
  '1-square',
  '1-square-fill',
  '2-circle',
  '2-circle-fill',
  '2-square',
  '2-square-fill',
  '3-circle',
  '3-circle-fill',
  '3-square',
  '3-square-fill',
  '4-circle',
  '4-circle-fill',
  '4-square',
  '4-square-fill',
  '5-circle',
  '5-circle-fill',
  '5-square',
  '5-square-fill',
  '6-circle',
  '6-circle-fill',
  '6-square',
  '6-square-fill',
  '7-circle',
  '7-circle-fill',
  '7-square',
  '7-square-fill',
  '8-circle',
  '8-circle-fill',
  '8-square',
  '8-square-fill',
  '9-circle',
  '9-circle-fill',
  '9-square',
  '9-square-fill',
  '123',
  'account',
  'account-filled',
  'activity',
  'add',
  'add-outline',
  'address',
  'address-book',
  'airplane',
  'airplane-engines',
  'airplane-engines-fill',
  'airplane-fill',
  'alarm',
  'alarm-fill',
  'Alerts, warnings, and signs',
  'alexa',
  'alias',
  'align-bottom',
  'align-center',
  'align-end',
  'align-left',
  'align-middle',
  'align-start',
  'align-top',
  'alipay',
  'alphabet',
  'alphabet-uppercase',
  'alt',
  'amazon',
  'amd',
  'android',
  'android2',
  'app',
  'app-indicator',
  'apple',
  'Apps',
  'archive',
  'archived',
  'archive-fill',
  'arrow',
  'arrow-45',
  'arrow-90',
  'arrow-90deg-down',
  'arrow-90deg-left',
  'arrow-90deg-right',
  'arrow-90deg-up',
  'arrow-bar-down',
  'arrow-bar-left',
  'arrow-bar-right',
  'arrow-bar-up',
  'arrow-circle-down',
  'arrow-circle-down-left',
  'arrow-circle-right',
  'arrow-circle-up-right',
  'arrow-clockwise',
  'arrow-counterclockwise',
  'arrow-down',
  'arrow-down-circle',
  'arrow-down-circle-fill',
  'arrow-down-left',
  'arrow-down-left-circle',
  'arrow-down-left-circle-fill',
  'arrow-down-left-square',
  'arrow-down-left-square-fill',
  'arrow-down-right',
  'arrow-down-right-circle',
  'arrow-down-right-circle-fill',
  'arrow-down-right-square',
  'arrow-down-right-square-fill',
  'arrow-down-short',
  'arrow-down-square',
  'arrow-down-square-fill',
  'arrow-down-up',
  'arrow-left',
  'arrow-left-circle',
  'arrow-left-circle-fill',
  'arrow-left-new',
  'arrow-left-right',
  'arrow-left-short',
  'arrow-left-square',
  'arrow-left-square-fill',
  'arrow-repeat',
  'arrow-return',
  'arrow-return-left',
  'arrow-return-right',
  'arrow-right',
  'arrow-right-circle',
  'arrow-right-circle-fill',
  'arrow-right-new',
  'arrow-right-short',
  'arrow-right-square',
  'arrow-right-square-fill',
  'arrows',
  'arrows-angle-contract',
  'arrows-angle-expand',
  'arrows-collapse',
  'arrows-collapse-vertical',
  'arrows-expand',
  'arrows-expand-vertical',
  'arrows-fullscreen',
  'arrows-left-right',
  'arrow-sm',
  'arrow-sm-45',
  'arrows-move',
  'arrows-vertical',
  'arrow-through-heart',
  'arrow-through-heart-fill',
  'arrow-up',
  'arrow-up-circle',
  'arrow-up-circle-fill',
  'arrow-up-left',
  'arrow-up-left-circle',
  'arrow-up-left-circle-fill',
  'arrow-up-left-square',
  'arrow-up-left-square-fill',
  'arrow-up-right',
  'arrow-up-right-circle',
  'arrow-up-right-circle-fill',
  'arrow-up-right-square',
  'arrow-up-right-square-fill',
  'arrow-up-short',
  'arrow-up-square',
  'arrow-up-square-fill',
  'aspect-ratio',
  'aspect-ratio-fill',
  'asterisk',
  'at',
  'atm',
  'atm-withdraw',
  'Aug',
  'award',
  'award-fill',
  'back',
  'backpack',
  'backpack2',
  'backpack2-fill',
  'backpack3',
  'backpack3-fill',
  'backpack4',
  'backpack4-fill',
  'backpack-fill',
  'backspace',
  'backspace-fill',
  'backspace-reverse',
  'backspace-reverse-fill',
  'badge-3d',
  'badge-3d-fill',
  'badge-4k',
  'badge-4k-fill',
  'badge-8k',
  'badge-8k-fill',
  'badge-ad',
  'badge-ad-fill',
  'badge-ar',
  'badge-ar-fill',
  'badge-bg',
  'badge-cc',
  'badge-cc-fill',
  'badge-hd',
  'badge-hd-fill',
  'Badges',
  'badge-sd',
  'badge-sd-fill',
  'badge-tm',
  'badge-tm-fill',
  'badge-vo',
  'badge-vo-fill',
  'badge-vr',
  'badge-vr-fill',
  'badge-wc',
  'badge-wc-fill',
  'bag',
  'bag-check',
  'bag-check-fill',
  'bag-dash',
  'bag-dash-fill',
  'bag-fill',
  'bag-fill-frame',
  'bag-frame',
  'bag-heart',
  'bag-heart-fill',
  'bag-plus',
  'bag-plus-fill',
  'bag-x',
  'bag-x-fill',
  'balloon',
  'balloon-fill',
  'balloon-heart',
  'balloon-heart-fill',
  'ban',
  'bandaid',
  'bandaid-fill',
  'ban-fill',
  'bank',
  'bank2',
  'bank-account',
  'bank-guarantee',
  'bank-station',
  'bar-chart',
  'bar-chart-fill',
  'bar-chart-line',
  'bar-chart-line-fill',
  'bar-chart-steps',
  'basket',
  'basket2',
  'basket2-fill',
  'basket3',
  'basket3-fill',
  'basket-fill',
  'batch-payment',
  'battery',
  'battery-charging',
  'battery-full',
  'battery-half',
  'beaker',
  'behance',
  'bell',
  'bell-fill',
  'bell-slash',
  'bell-slash-fill',
  'beneficiaries',
  'between-my-account',
  'bezier',
  'bezier2',
  'bicycle',
  'bill',
  'bill-payment',
  'bills-top-ups',
  'bing',
  'binoculars',
  'binoculars-fill',
  'blockquote-left',
  'blockquote-right',
  'bluetooth',
  'body-text',
  'bonds',
  'book',
  'book-fill',
  'book-half',
  'bookmark',
  'bookmark-check',
  'bookmark-check-fill',
  'bookmark-dash',
  'bookmark-dash-fill',
  'bookmark-fill',
  'bookmark-fill-frame',
  'bookmark-frame',
  'bookmark-heart',
  'bookmark-heart-fill',
  'bookmark-plus',
  'bookmark-plus-fill',
  'bookmarks',
  'bookmarks-fill',
  'bookmark-star',
  'bookmark-star-fill',
  'bookmark-x',
  'bookmark-x-fill',
  'books',
  'bookshelf',
  'boombox',
  'boombox-fill',
  'bootstrap',
  'bootstrap-fill',
  'bootstrap-reboot',
  'border',
  'border-all',
  'border-bottom',
  'border-center',
  'border-inner',
  'border-left',
  'border-middle',
  'border-outer',
  'border-right',
  'border-style',
  'border-top',
  'border-width',
  'bounding-box',
  'bounding-box-circles',
  'Box arrows',
  'box',
  'box2',
  'box2-fill',
  'box2-heart',
  'box2-heart-fill',
  'box-arrow',
  'box-arrow-45',
  'box-arrow-bg',
  'box-arrow-bg-45',
  'box-arrow-down',
  'box-arrow-down-left',
  'box-arrow-down-right',
  'box-arrow-in-down',
  'box-arrow-in-down-left',
  'box-arrow-in-down-right',
  'box-arrow-in-left',
  'box-arrow-in-right',
  'box-arrow-in-up',
  'box-arrow-in-up-left',
  'box-arrow-in-up-right',
  'box-arrow-left',
  'box-arrow-right',
  'box-arrow-up',
  'box-arrow-up-left',
  'box-arrow-up-right',
  'boxes',
  'box-fill',
  'box-seam',
  'box-seam-fill',
  'braces',
  'braces-asterisk',
  'bricks',
  'briefcase',
  'briefcase-fill',
  'brightness-alt-high',
  'brightness-alt-high-fill',
  'brightness-alt-low',
  'brightness-alt-low-fill',
  'brightness-high',
  'brightness-high-fill',
  'brightness-low',
  'brightness-low-fill',
  'brilliance',
  'broadcast',
  'broadcast-pin',
  'browser-chrome',
  'browser-edge',
  'browser-firefox',
  'browser-safari',
  'brush',
  'brush-fill',
  'bubble-chat',
  'bucket',
  'bucket-fill',
  'bug',
  'bug-fill',
  'building',
  'building-add',
  'building-check',
  'building-cutout',
  'building-dash',
  'building-down',
  'building-exclamation',
  'building-fill',
  'building-fill-add',
  'building-fill-check',
  'building-fill-cutout',
  'building-fill-dash',
  'building-fill-down',
  'building-fill-exclamation',
  'building-fill-gear',
  'building-fill-lock',
  'building-fill-slash',
  'building-fill-up',
  'building-fill-x',
  'building-gear',
  'building-lock',
  'buildings',
  'buildings-fill',
  'building-slash',
  'building-up',
  'building-x',
  'bullseye',
  'bus-front',
  'bus-front-fill',
  'cake',
  'cake2',
  'cake2-fill',
  'cake-fill',
  'calculator',
  'calculator-fill',
  'calendar',
  'calendar2',
  'calendar2-check',
  'calendar2-check-fill',
  'calendar2-date',
  'calendar2-date-fill',
  'calendar2-day',
  'calendar2-day-fill',
  'calendar2-event',
  'calendar2-event-fill',
  'calendar2-fill',
  'calendar2-fill-frame',
  'calendar2-frame',
  'calendar2-heart',
  'calendar2-heart-fill',
  'calendar2-minus',
  'calendar2-minus-fill',
  'calendar2-month',
  'calendar2-month-fill',
  'calendar2-plus',
  'calendar2-plus-fill',
  'calendar2-range',
  'calendar2-range-fill',
  'calendar2-week',
  'calendar2-week-fill',
  'calendar2-x',
  'calendar2-x-fill',
  'calendar3',
  'calendar3-event',
  'calendar3-event-fill',
  'calendar3-fill',
  'calendar3-fill-frame',
  'calendar3-frame',
  'calendar3-range',
  'calendar3-range-fill',
  'calendar3-week',
  'calendar3-week-fill',
  'calendar4',
  'calendar4-event',
  'calendar4-frame',
  'calendar4-range',
  'calendar4-week',
  'calendar-blank',
  'calendar-check',
  'calendar-check-fill',
  'calendar-date',
  'calendar-date-fill',
  'calendar-day',
  'calendar-day-fill',
  'calendar-event',
  'calendar-event-fill',
  'calendar-fill',
  'calendar-fill-frame',
  'calendar-frame',
  'calendar-heart',
  'calendar-heart-fill',
  'calendar-minus',
  'calendar-minus-fill',
  'calendar-month',
  'calendar-month-fill',
  'calendar-plus',
  'calendar-plus-fill',
  'calendar-range',
  'calendar-range-fill',
  'calendar-week',
  'calendar-week-fill',
  'calendar-x',
  'calendar-x-fill',
  'camera',
  'camera2',
  'camera-fill',
  'camera-reels',
  'camera-reels-fill',
  'camera-video',
  'camera-video-fill',
  'camera-video-off',
  'camera-video-off-fill',
  'cancel',
  'cancel-input',
  'capslock',
  'capslock-fill',
  'capsule',
  'capsule-pill',
  'card',
  'card-checklist',
  'card-heading',
  'card-image',
  'card-list',
  'cards',
  'card-text',
  'caret-down',
  'caret-down-fill',
  'caret-down-square',
  'caret-down-square-fill',
  'caret-left',
  'caret-left-fill',
  'caret-left-square',
  'caret-left-square-fill',
  'caret-right',
  'caret-right-fill',
  'caret-right-square',
  'caret-right-square-fill',
  'Carets',
  'caret-up',
  'caret-up-fill',
  'caret-up-square',
  'caret-up-square-fill',
  'car-front',
  'car-front-fill',
  'cart',
  'cart2',
  'cart3',
  'cart4',
  'cart-check',
  'cart-check-fill',
  'cart-dash',
  'cart-dash-fill',
  'cart-fill',
  'cart-fill-frame',
  'cart-frame',
  'cart-plus',
  'cart-plus-fill',
  'cart-x',
  'cart-x-fill',
  'cases',
  'cash',
  'cash-coin',
  'cash-stack',
  'cassette',
  'cassette-fill',
  'cast',
  'cc-circle',
  'cc-circle-fill',
  'c-circle',
  'c-circle-fill',
  'cc-square',
  'cc-square-fill',
  'certificate',
  'chart',
  'chartbar-horizontal',
  'chart-centered-text',
  'chart-donut',
  'chart-pie-slice',
  'chat',
  'chat-dots',
  'chat-dots-fill',
  'chat-dots-new',
  'chat-fill',
  'chat-heart',
  'chat-heart-fill',
  'chat-left',
  'chat-left-dots',
  'chat-left-dots-fill',
  'chat-left-fill',
  'chat-left-heart',
  'chat-left-heart-fill',
  'chat-left-quote',
  'chat-left-quote-fill',
  'chat-left-text',
  'chat-left-text-fill',
  'chat-quote',
  'chat-quote-fill',
  'chat-right',
  'chat-right-dots',
  'chat-right-dots-fill',
  'chat-right-fill',
  'chat-right-heart',
  'chat-right-heart-fill',
  'chat-right-quote',
  'chat-right-quote-fill',
  'chat-right-text',
  'chat-right-text-fill',
  'chat-square',
  'chat-square-dots',
  'chat-square-dots-fill',
  'chat-square-fill',
  'chat-square-heart',
  'chat-square-heart-fill',
  'chat-square-quote',
  'chat-square-quote-fill',
  'chat-square-text',
  'chat-square-text-fill',
  'chat-text',
  'chat-text-fill',
  'check',
  'check2',
  'check2-all',
  'check2-circle',
  'check2-square',
  'check-all',
  'check-box',
  'check-circle',
  'check-circle-fill',
  'check-circle-green',
  'checked',
  'check-failed',
  'checking',
  'check-lg',
  'check-lg-1',
  'checks',
  'check-sm',
  'check-square',
  'check-square-fill',
  'chevron-bar-contract',
  'chevron-bar-down',
  'chevron-bar-expand',
  'chevron-bar-left',
  'chevron-bar-right',
  'chevron-bar-up',
  'chevron-compact-down',
  'chevron-compact-left',
  'chevron-compact-right',
  'chevron-compact-up',
  'chevron-contract',
  'chevron-double-down',
  'chevron-double-left',
  'chevron-double-right',
  'chevron-double-up',
  'chevron-down',
  'chevron-expand',
  'chevron-left',
  'chevron-right',
  'Chevrons',
  'chevron-up',
  'chevron-updown',
  'circle',
  'circle-fill',
  'circle-half',
  'circle-square',
  'citas-transfer',
  'claim-request',
  'clipboard',
  'clipboard-1',
  'clipboard2',
  'clipboard2-base',
  'clipboard2-check',
  'clipboard2-check-fill',
  'clipboard2-data',
  'clipboard2-data-fill',
  'clipboard2-fill',
  'clipboard2-fill-base',
  'clipboard2-heart',
  'clipboard2-heart-fill',
  'clipboard2-minus',
  'clipboard2-minus-fill',
  'clipboard2-plus',
  'clipboard2-plus-fill',
  'clipboard2-pulse',
  'clipboard2-pulse-fill',
  'clipboard2-x',
  'clipboard2-x-fill',
  'clipboard-check',
  'clipboard-check-fill',
  'clipboard-data',
  'clipboard-data-fill',
  'clipboard-fill',
  'clipboard-fill-base',
  'clipboard-heart',
  'clipboard-heart-fill',
  'clipboard-minus',
  'clipboard-minus-fill',
  'clipboard-plus',
  'clipboard-plus-fill',
  'clipboard-pulse',
  'clipboard-x',
  'clipboard-x-fill',
  'clipboard-x-fill-1',
  'clock',
  'clock-fill',
  'clock-history',
  'clock-new',
  'close',
  'close-new',
  'cloud',
  'cloud-arrow-down',
  'cloud-arrow-down-fill',
  'cloud-arrow-up',
  'cloud-arrow-up-fill',
  'cloud-check',
  'cloud-check-fill',
  'cloud-download',
  'cloud-download-fill',
  'cloud-drizzle',
  'cloud-drizzle-fill',
  'cloud-fill',
  'cloud-fog',
  'cloud-fog2',
  'cloud-fog2-fill',
  'cloud-fog-fill',
  'cloud-hail',
  'cloud-hail-fill',
  'cloud-haze',
  'cloud-haze-1',
  'cloud-haze2-fill',
  'cloud-haze-fill',
  'cloud-lightning',
  'cloud-lightning-fill',
  'cloud-lightning-rain',
  'cloud-lightning-rain-fill',
  'cloud-minus',
  'cloud-minus-fill',
  'cloud-moon',
  'cloud-moon-fill',
  'cloud-plus',
  'cloud-plus-fill',
  'cloud-rain',
  'cloud-rain-fill',
  'cloud-rain-heavy',
  'cloud-rain-heavy-fill',
  'clouds',
  'clouds-fill',
  'cloud-slash',
  'cloud-slash-fill',
  'cloud-sleet',
  'cloud-sleet-fill',
  'cloud-snow',
  'cloud-snow-fill',
  'cloud-sun',
  'cloud-sun-fill',
  'cloud-upload',
  'cloud-upload-fill',
  'cloudy',
  'cloudy-fill',
  'code',
  'code-slash',
  'code-square',
  'coin',
  'collection',
  'collection-fill',
  'collection-play',
  'collection-play-fill',
  'columns',
  'columns-gap',
  'command',
  'Commerce',
  'Communications',
  'company-stamp',
  'compass',
  'compass-fill',
  'Component 2',
  'cone',
  'cone-striped',
  'contacts',
  'controller',
  'Controls',
  'cookie',
  'copy',
  'copy-new',
  'correct',
  'cpu',
  'cpu-fill',
  'credit-card',
  'credit-card-2-back',
  'credit-card-2-back-fill',
  'credit-card-2-front',
  'credit-card-2-front-fill',
  'credit-card-connect',
  'credit-card-fill',
  'credit-card-new',
  'crop',
  'crosshair',
  'crosshair2',
  'c-square',
  'c-square-fill',
  'cup',
  'cup-fill',
  'cup-hot',
  'cup-hot-fill',
  'cup-straw',
  'currency-bitcoin',
  'currency-dollar',
  'currency-euro',
  'currency-exchange',
  'currency-pound',
  'currency-rupee',
  'currency-yen',
  'current-account',
  'cursor',
  'cursor-fill',
  'cursor-text',
  'custom-tax',
  'danger',
  'danger-rhombus',
  'dash',
  'dashboard',
  'dashboard-filled',
  'dash-circle',
  'dash-circle-dotted',
  'dash-circle-fill',
  'dash-lg',
  'dash-lg-1',
  'dash-square',
  'dash-square-dotted',
  'dash-square-fill',
  'Data',
  'database',
  'database-add',
  'database-check',
  'database-cutout',
  'database-dash',
  'database-down',
  'database-exclamation',
  'database-fill',
  'database-fill-add',
  'database-fill-check',
  'database-fill-cutout',
  'database-fill-dash',
  'database-fill-down',
  'database-fill-exclamation',
  'database-fill-gear',
  'database-fill-lock',
  'database-fill-slash',
  'database-fill-up',
  'database-fill-x',
  'database-gear',
  'database-lock',
  'database-slash',
  'database-up',
  'database-x',
  'Date and time',
  'day',
  'day-19',
  'default-bookmark',
  'delete',
  'desktop',
  'device-hdd',
  'device-hdd-fill',
  'Devices',
  'device-ssd',
  'device-ssd-fill',
  'diagram-2',
  'diagram-2-fill',
  'diagram-3',
  'diagram-3-fill',
  'diamond',
  'diamond-fill',
  'diamond-half',
  'dice-1',
  'dice-1-fill',
  'dice-2',
  'dice-2-fill',
  'dice-3',
  'dice-3-fill',
  'dice-4',
  'dice-4-fill',
  'dice-5',
  'dice-5-fill',
  'dice-6',
  'dice-6-fill',
  'dice-bg',
  'disc',
  'disc-fill',
  'discord',
  'display',
  'display-fill',
  'displayport',
  'displayport-fill',
  'distribute-horizontal',
  'distribute-vertical',
  'domestic-tax',
  'door-closed',
  'door-closed-fill',
  'door-open',
  'door-open-fill',
  'dot',
  'dots-three',
  'dots-three-circle-vertical',
  'dots-three-vertical',
  'dot-three',
  'dot-three-circle-vertical',
  'dot-three-vertical',
  'double-left',
  'double-right',
  'down',
  'download',
  'download-simple',
  'dpad',
  'dpad-fill',
  'dribbble',
  'dropbox',
  'droplet',
  'droplet-fill',
  'droplet-half',
  'duffle',
  'duffle-fill',
  'ear',
  'earbuds',
  'ear-fill',
  'easel',
  'easel2',
  'easel2-fill',
  'easel3',
  'easel3-fill',
  'easel-fill',
  'edit',
  'egg',
  'egg-fill',
  'egg-fried',
  'egg-fried-fill',
  'eject',
  'eject-fill',
  'ekyc',
  'electricity',
  'electricity-v',
  'Emoji',
  'emoji-angry',
  'emoji-angry-fill',
  'emoji-astonished',
  'emoji-astonished-fill',
  'emoji-dizzy',
  'emoji-dizzy-fill',
  'emoji-expressionless',
  'emoji-expressionless-fill',
  'emoji-frown',
  'emoji-frown-1',
  'emoji-frown-fill',
  'emoji-grimace',
  'emoji-grimace-fill',
  'emoji-grin',
  'emoji-grin-fill',
  'emoji-heart-eyes',
  'emoji-heart-eyes-fill',
  'emoji-kiss',
  'emoji-kiss-fill',
  'emoji-laughing',
  'emoji-laughing-fill',
  'emoji-neutral',
  'emoji-neutral-fill',
  'emoji-smile',
  'emoji-smile-fill',
  'emoji-smile-upside-down',
  'emoji-smile-upside-down-fill',
  'emoji-sunglasses',
  'emoji-sunglasses-fill',
  'emoji-surprise',
  'emoji-surprise-fill',
  'emoji-tear',
  'emoji-tear-fill',
  'emoji-wink',
  'emoji-wink-fill',
  'empty-bank',
  'empty-pattern',
  'Entertainment',
  'envelope',
  'envelope-arrow-down',
  'envelope-arrow-down-fill',
  'envelope-arrow-up',
  'envelope-arrow-up-fill',
  'envelope-at',
  'envelope-at-fill',
  'envelope-check',
  'envelope-check-fill',
  'envelope-dash',
  'envelope-dash-fill',
  'envelope-exclamation',
  'envelope-exclamation-fill',
  'envelope-fill',
  'envelope-heart',
  'envelope-heart-fill',
  'envelope-open',
  'envelope-open-fill',
  'envelope-open-heart',
  'envelope-open-heart-fill',
  'envelope-paper',
  'envelope-paper-fill',
  'envelope-paper-heart',
  'envelope-paper-heart-fill',
  'envelope-plus',
  'envelope-plus-fill',
  'envelope-slash',
  'envelope-slash-fill',
  'envelope-x',
  'envelope-x-fill',
  'equities',
  'eraser',
  'eraser-fill',
  'error-circle',
  'escape',
  'ethernet',
  'ev-front',
  'ev-front-fill',
  'ev-station',
  'ev-station-fill',
  'exclamation',
  'exclamation-circle',
  'exclamation-circle-fill',
  'exclamation-diamond',
  'exclamation-diamond-fill',
  'exclamation-lg',
  'exclamation-octagon',
  'exclamation-octagon-fill',
  'exclamation-square',
  'exclamation-square-fill',
  'exclamation-triangle',
  'exclamation-triangle-fill',
  'exclude',
  'explicit',
  'explicit-fill',
  'exposure',
  'eye',
  'eyedropper',
  'eye-fill',
  'eyeglasses',
  'eye-slash',
  'eye-slash-fill',
  'facebook',
  'face-id',
  'fan',
  'fast-forward',
  'fast-forward-btn',
  'fast-forward-btn-fill',
  'fast-forward-circle',
  'fast-forward-circle-fill',
  'fast-forward-fill',
  'feather',
  'feather2',
  'file',
  'file-arrow-down',
  'file-arrow-down-fill',
  'file-arrow-up',
  'file-arrow-up-fill',
  'file-bar-graph',
  'file-bar-graph-fill',
  'file-binary',
  'file-binary-fill',
  'file-break',
  'file-break-fill',
  'file-check',
  'file-check-fill',
  'file-code',
  'file-code-fill',
  'file-diff',
  'file-diff-fill',
  'file-earmark',
  'file-earmark-arrow-down',
  'file-earmark-arrow-down-fill',
  'file-earmark-arrow-up',
  'file-earmark-arrow-up-fill',
  'file-earmark-bar-graph',
  'file-earmark-bar-graph-fill',
  'file-earmark-binary',
  'file-earmark-binary-fill',
  'file-earmark-break',
  'file-earmark-break-fill',
  'file-earmark-check',
  'file-earmark-check-fill',
  'file-earmark-code',
  'file-earmark-code-fill',
  'file-earmark-diff',
  'file-earmark-diff-fill',
  'file-earmark-easel',
  'file-earmark-easel-fill',
  'file-earmark-excel',
  'file-earmark-excel-fill',
  'file-earmark-fill',
  'file-earmark-fill-frame',
  'file-earmark-font',
  'file-earmark-font-fill',
  'file-earmark-frame',
  'file-earmark-image',
  'file-earmark-image-fill',
  'file-earmark-lock',
  'file-earmark-lock2',
  'file-earmark-lock2-fill',
  'file-earmark-lock-fill',
  'file-earmark-medical',
  'file-earmark-medical-fill',
  'file-earmark-minus',
  'file-earmark-minus-fill',
  'file-earmark-music',
  'file-earmark-music-fill',
  'file-earmark-pdf',
  'file-earmark-pdf-fill',
  'file-earmark-person',
  'file-earmark-person-fill',
  'file-earmark-play',
  'file-earmark-play-fill',
  'file-earmark-plus',
  'file-earmark-plus-fill',
  'file-earmark-post',
  'file-earmark-post-fill',
  'file-earmark-ppt',
  'file-earmark-ppt-fill',
  'file-earmark-richtext',
  'file-earmark-richtext-fill',
  'file-earmark-ruled',
  'file-earmark-ruled-fill',
  'file-earmark-slides',
  'file-earmark-slides-fill',
  'file-earmark-spreadsheet',
  'file-earmark-spreadsheet-fill',
  'file-earmark-text',
  'file-earmark-text-fill',
  'file-earmark-word',
  'file-earmark-word-fill',
  'file-earmark-x',
  'file-earmark-x-fill',
  'file-earmark-zip',
  'file-earmark-zip-fill',
  'file-easel',
  'file-easel-fill',
  'file-excel',
  'file-excel-fill',
  'file-fill',
  'file-fill-frame',
  'file-font',
  'file-font-fill',
  'file-frame',
  'file-image',
  'file-image-fill',
  'file-lock',
  'file-lock2',
  'file-lock2-fill',
  'file-lock-fill',
  'file-medical',
  'file-medical-fill',
  'file-minus',
  'file-minus-fill',
  'file-music',
  'file-music-fill',
  'file-pdf',
  'file-pdf-fill',
  'file-person',
  'file-person-fill',
  'file-play',
  'file-play-fill',
  'file-plus',
  'file-plus-fill',
  'file-post',
  'file-post-fill',
  'file-ppt',
  'file-ppt-fill',
  'file-richtext',
  'file-richtext-fill',
  'file-ruled',
  'file-ruled-fill',
  'Files and folders',
  'files',
  'files-alt',
  'file-slides',
  'file-slides-fill',
  'file-spreadsheet',
  'file-spreadsheet-fill',
  'file-text',
  'file-text-fill',
  'filetype-aac',
  'filetype-ai',
  'filetype-bmp',
  'filetype-cs',
  'filetype-css',
  'filetype-csv',
  'filetype-doc',
  'filetype-docx',
  'filetype-exe',
  'filetype-gif',
  'filetype-heic',
  'filetype-html',
  'filetype-java',
  'filetype-jpg',
  'filetype-js',
  'filetype-json',
  'filetype-jsx',
  'filetype-key',
  'filetype-m4p',
  'filetype-md',
  'filetype-mdx',
  'filetype-mov',
  'filetype-mp3',
  'filetype-mp4',
  'filetype-otf',
  'filetype-pdf',
  'filetype-php',
  'filetype-png',
  'filetype-ppt',
  'filetype-pptx',
  'filetype-psd',
  'filetype-py',
  'filetype-raw',
  'filetype-rb',
  'filetype-sass',
  'filetype-scss',
  'filetype-sh',
  'filetype-sql',
  'filetype',
  'filetype-tiff',
  'filetype-tsx',
  'filetype-ttf',
  'filetype-txt',
  'filetype-wav',
  'filetype-woff',
  'filetype-xls',
  'filetype-xls-1',
  'filetype-xml',
  'filetype-yml',
  'file-word',
  'file-word-fill',
  'file-x',
  'file-x-fill',
  'file-zip',
  'file-zip-fill',
  'film',
  'filter',
  'filter-circle',
  'filter-circle-fill',
  'filter-group',
  'filter-left',
  'filter-right',
  'filter-square',
  'filter-square-fill',
  'fingerprint',
  'fire',
  'flag',
  'flag-fill',
  'flash-off',
  'flash-on',
  'flask',
  'flask-florence',
  'floppy',
  'floppy2',
  'floppy2-fill',
  'floppy-fill',
  'flower1',
  'flower2',
  'flower3',
  'folder',
  'folder2',
  'folder2-open',
  'folder-check',
  'folder-fill',
  'folder-minus',
  'folder-plus',
  'folder-symlink',
  'folder-symlink-fill',
  'folder-x',
  'fonts',
  'forward',
  'forward-fill',
  'Fri',
  'front',
  'fuel-pump',
  'fuel-pump-diesel',
  'fuel-pump-diesel-fill',
  'fuel-pump-fill',
  'fullscreen',
  'fullscreen-exit',
  'funds',
  'funnel',
  'funnel-fill',
  'funnel-simple',
  'fx',
  'gear',
  'gear-fill',
  'gear-fine',
  'gear-wide',
  'gear-wide-connected',
  'gem',
  'gender-ambiguous',
  'gender-female',
  'gender-male',
  'gender-neuter',
  'gender-trans',
  'geo',
  'geo-alt',
  'geo-alt-fill',
  'geo-fill',
  'gift',
  'gift-fill',
  'git',
  'github',
  'gitlab',
  'globe',
  'globe2',
  'globe-americas',
  'globe-asia-australia',
  'globe-central-south-asia',
  'globe-europe-africa',
  'globe-simple',
  'google',
  'google-play',
  'gpu-card',
  'graph-down',
  'graph-down-arrow',
  'Graphics',
  'graph-up',
  'graph-up-arrow',
  'gray-circle',
  'grid',
  'grid-1x2',
  'grid-1x2-fill',
  'grid-3x2',
  'grid-3x2-gap',
  'grid-3x2-gap-fill',
  'grid-3x3',
  'grid-3x3-gap',
  'grid-3x3-gap-fill',
  'grid-fill',
  'grip-horizontal',
  'grip-vertical',
  'Group 301',
  'Group 303',
  'guide',
  'hamburger-menu',
  'hamburger-menu-new',
  'hammer',
  'handbag',
  'handbag-fill',
  'hand-index',
  'hand-index-fill',
  'hand-index-thumb',
  'hand-index-thumb-fill',
  'Hands',
  'hand-thumbs-down',
  'hand-thumbs-down-fill',
  'hand-thumbs-up',
  'hand-thumbs-up-fill',
  'hash',
  'h-circle',
  'h-circle-fill',
  'hdd',
  'hdd-fill',
  'hdd-network',
  'hdd-network-fill',
  'hdd-rack',
  'hdd-rack-fill',
  'hdd-stack',
  'hdd-stack-fill',
  'hdmi',
  'hdmi-fill',
  'headphones',
  'headset',
  'headset-vr',
  'heart',
  'heart-arrow',
  'heartbreak',
  'heartbreak-fill',
  'heart-fill',
  'heart-fill-1',
  'heart-half',
  'heart-pulse',
  'heart-pulse-fill',
  'hearts',
  'help',
  'heptagon',
  'heptagon-fill',
  'heptagon-half',
  'hexagon',
  'hexagon-fill',
  'hexagon-half',
  'hide-eye',
  'Hierachy',
  'hierarchy',
  'highlighter',
  'highlights',
  'history-update',
  'home-phone',
  'hospital',
  'hospital-fill',
  'hourglass',
  'hourglass-bottom',
  'hourglass-split',
  'hourglass-top',
  'house',
  'house-add',
  'house-add-fill',
  'house-check',
  'house-check-fill',
  'house-cutout',
  'house-dash',
  'house-dash-fill',
  'house-door',
  'house-door-fill',
  'house-down',
  'house-down-fill',
  'house-exclamation',
  'house-exclamation-fill',
  'house-fill',
  'house-fill-cutout',
  'house-gear',
  'house-gear-fill',
  'house-heart',
  'house-heart-fill',
  'house-lock',
  'house-lock-fill',
  'houses',
  'houses-fill',
  'house-slash',
  'house-slash-fill',
  'house-up',
  'house-up-fill',
  'house-x',
  'house-x-fill',
  'housing-service',
  'hr',
  'h-square',
  'h-square-fill',
  'hurricane',
  'hypnotize',
  'i-lg',
  'image',
  'image-alt',
  'image-fill',
  'images',
  'inbox',
  'inboxes',
  'inboxes-fill',
  'inbox-fill',
  'incoming',
  'indent',
  'infinity',
  'info',
  'info-circle',
  'info-circle-fill',
  'info-lg',
  'info-new',
  'info-square',
  'info-square-fill',
  'input-cursor',
  'input-cursor-text',
  'insert-card',
  'instagram',
  'insurance',
  'insurance-red',
  'internet',
  'intersect',
  'investment',
  'investment-red',
  'itech',
  'itech-circle',
  'job-roles',
  'journal',
  'journal-album',
  'journal-arrow-down',
  'journal-arrow-up',
  'journal-bookmark',
  'journal-bookmark-fill',
  'journal-check',
  'journal-code',
  'journal-frame',
  'journal-medical',
  'journal-minus',
  'journal-plus',
  'journal-richtext',
  'journals',
  'journal-text',
  'journal-x',
  'joystick',
  'justify',
  'justify-left',
  'justify-right',
  'kanban',
  'kanban-fill',
  'key',
  'keyboard',
  'keyboard-fill',
  'key-fill',
  'kid-account',
  'ladder',
  'lamp',
  'lamp-fill',
  'lan',
  'language',
  'laptop',
  'laptop-fill',
  'layer-backward',
  'layer-forward',
  'layers',
  'layers-fill',
  'layers-half',
  'Layout',
  'layout-sidebar',
  'layout-sidebar-inset',
  'layout-sidebar-inset-reverse',
  'layout-sidebar-reverse',
  'layout-split',
  'layout-text-sidebar',
  'layout-text-sidebar-reverse',
  'layout-text-window',
  'layout-text-window-reverse',
  'layout-three-columns',
  'layout-wtf',
  'leaf',
  'left',
  'letters-of-credit',
  'level',
  'life-insurance',
  'life-preserver',
  'lightbulb',
  'lightbulb-filament',
  'lightbulb-fill',
  'lightbulb-off',
  'lightbulb-off-fill',
  'lightning',
  'lightning-charge',
  'lightning-charge-fill',
  'lightning-fill',
  'line',
  'link',
  'link-45deg',
  'linkedin',
  'list',
  'list-check',
  'list-columns',
  'list-columns-reverse',
  'list-nested',
  'list-ol',
  'list-stars',
  'list-task',
  'list-ul',
  'loading',
  'loan',
  'loans',
  'location',
  'lock',
  'lock-fill',
  'lock-simple',
  'Love',
  'luggage',
  'luggage-fill',
  'lungs',
  'lungs-fill',
  'magic',
  'magnet',
  'magnet-fill',
  'magnifying-glass',
  'mail',
  'mailbox',
  'mailbox-1',
  'mailbox2',
  'mailbox2-flag',
  'mailbox-flag',
  'map',
  'map-fill',
  'mark-as-read',
  'markdown',
  'markdown-fill',
  'marker-tip',
  'mask',
  'mastodon',
  'measuring-cup',
  'Media',
  'Medical',
  'medium',
  'megaphone',
  'megaphone-fill',
  'memory',
  'menu-app',
  'menu-app-fill',
  'menu-button',
  'menu-button-fill',
  'menu-button-wide',
  'menu-button-wide-fill',
  'menu-down',
  'menu-items',
  'menu-up',
  'merchant',
  'messenger',
  'meta',
  'mic',
  'mic-fill',
  'mic-mute',
  'mic-mute-fill',
  'microsoft',
  'microsoft-teams',
  'minecart',
  'minecart-loaded',
  'minus',
  'minus-sm',
  'Misc',
  'mobile',
  'mobile-money',
  'mobile-number',
  'modem',
  'modem-fill',
  'moisture',
  'moon',
  'moon-fill',
  'moon-stars',
  'moon-stars-fill',
  'mortarboard',
  'mortarboard-fill',
  'mortgage',
  'motherboard',
  'motherboard-fill',
  'mountains',
  'mouse',
  'mouse2',
  'mouse2-fill',
  'mouse3',
  'mouse3-fill',
  'mouse-fill',
  'multiple-bookmark',
  'music-note',
  'music-note-beamed',
  'music-note-list',
  'music-player',
  'music-player-fill',
  'mute',
  'newspaper',
  'night',
  'nintendo-switch',
  'node-minus',
  'node-minus-fill',
  'node-plus',
  'node-plus-fill',
  'noise-reduction',
  'not-found',
  'notification',
  'notification-active',
  'notification-badge',
  'nut',
  'nut-fill',
  'nvidia',
  'nvme',
  'nvme-fill',
  'octagon',
  'octagon-fill',
  'octagon-half',
  'off-canvas-menu',
  'online-payments',
  'online-queue',
  'opencollective',
  'optical-audio',
  'optical-audio-fill',
  'optimiser',
  'option',
  'other',
  'ott',
  'outgoing',
  'outlet',
  'overdraft-account',
  'paint-bucket',
  'palette',
  'palette2',
  'palette-fill',
  'paperclip',
  'paragraph',
  'pass',
  'pass-1',
  'pass-fill',
  'pass-fill-1',
  'passport',
  'passport-fill',
  'patch-bg',
  'patch-check',
  'patch-check-fill',
  'patch-exclamation',
  'patch-exclamation-fill',
  'patch-minus',
  'patch-minus-fill',
  'patch-plus',
  'patch-plus-fill',
  'patch-question',
  'patch-question-fill',
  'pause',
  'pause-btn',
  'pause-btn-fill',
  'pause-circle',
  'pause-circle-fill',
  'pause-fill',
  'pause-sm',
  'pay-and-transfer',
  'paypal',
  'payroll',
  'pay-transfer',
  'pay-transfer-filled',
  'pc',
  'pc-display',
  'pc-display-horizontal',
  'pc-horizontal',
  'pci-card',
  'pci-card-network',
  'pci-card-sound',
  'p-circle',
  'p-circle-fill',
  'peace',
  'peace-fill',
  'pen',
  'pencil',
  'pencil-fill',
  'pencil-line',
  'pencil-simple-line',
  'pencil-square',
  'pending',
  'pen-fill',
  'pentagon',
  'pentagon-fill',
  'pentagon-half',
  'people',
  'people-fill',
  'percent',
  'person',
  'person-1',
  'person-add',
  'person-arms-up',
  'person-badge',
  'person-badge-fill',
  'person-bounding-box',
  'person-check',
  'person-circle',
  'person-dash',
  'person-down',
  'person-exclamation',
  'person-fill',
  'person-fill-1',
  'person-fill-add',
  'person-fill-check',
  'person-fill-dash',
  'person-fill-down',
  'person-fill-exclamation',
  'person-fill-gear',
  'person-fill-lock',
  'person-fill-slash',
  'person-fill-up',
  'person-fill-x',
  'person-gear',
  'person-heart',
  'person-hearts',
  'person-lines-fill',
  'person-lock',
  'person-raised-hand',
  'person-rolodex',
  'person-slash',
  'person-square',
  'person-standing',
  'person-standing-dress',
  'person-up',
  'person-vcard',
  'person-vcard-fill',
  'person-video',
  'person-video2',
  'person-video3',
  'person-walking',
  'person-wheelchair',
  'person-workspace',
  'person-x',
  'phone',
  'phone-fill',
  'phone-flip',
  'phone-landscape',
  'phone-landscape-fill',
  'phone-vibrate',
  'phone-vibrate-fill',
  'photo-library',
  'pie-chart',
  'pie-chart-fill',
  'piggy-bank',
  'piggy-bank-fill',
  'pin',
  'pin-angle',
  'pin-angle-fill',
  'pin-fill',
  'pin-map',
  'pin-map-fill',
  'pinterest',
  'pip',
  'pip-fill',
  'play',
  'play-btn',
  'play-btn-fill',
  'play-circle',
  'play-circle-fill',
  'play-fill',
  'play-sm',
  'playstation',
  'plug',
  'plug-fill',
  'plugin',
  'plus',
  'plus-circle',
  'plus-circle-dotted',
  'plus-circle-fill',
  'plus-lg',
  'plus-lg-1',
  'plus-new',
  'plus-slash-minus',
  'plus-sm',
  'plus-square',
  'plus-square-dotted',
  'plus-square-fill',
  'policy',
  'postage',
  'postage-fill',
  'postage-heart',
  'postage-heart-fill',
  'postcard',
  'postcard-fill',
  'postcard-heart',
  'postcard-heart-fill',
  'postpaid',
  'power',
  'prescription',
  'prescription2',
  'printer',
  'printer-fill',
  'product-hub',
  'products',
  'profile',
  'prohibitInset',
  'projector',
  'projector-fill',
  'p-square',
  'p-square-fill',
  'puzzle',
  'puzzle-fill',
  'qr',
  'qr-code',
  'qr-code-scan',
  'question',
  'question-circle',
  'question-circle-fill',
  'question-diamond',
  'question-diamond-fill',
  'question-lg',
  'question-mark',
  'question-octagon',
  'question-octagon-fill',
  'question-square',
  'question-square-fill',
  'quora',
  'quote',
  'radar',
  'radioactive',
  'rainbow',
  'r-circle',
  'r-circle-fill',
  'rdb',
  'Real world',
  'receipt',
  'receipt-cutoff',
  'reception-0',
  'reception-1',
  'reception-2',
  'reception-3',
  'reception-4',
  'record',
  'record2',
  'record2-fill',
  'record-btn',
  'record-btn-fill',
  'record-circle',
  'record-circle-fill',
  'record-fill',
  'record-sm',
  'recycle',
  'reddit',
  'regex',
  'remind',
  'repeat',
  'repeat-1',
  'replace',
  'reply',
  'reply-all',
  'reply-all-fill',
  'reply-fill',
  'request',
  'request-filled',
  'requisitions',
  'review-bill-payment',
  'reward',
  'rewind',
  'rewind-btn',
  'rewind-btn-fill',
  'rewind-circle',
  'rewind-circle-fill',
  'rewind-fill',
  'right',
  'robot',
  'rocket',
  'rocket-fill',
  'rocket-takeoff',
  'rocket-takeoff-fill',
  'role',
  'router',
  'router-fill',
  'r-square',
  'r-square-fill',
  'rss',
  'rss-fill',
  'rule',
  'rulers',
  'safe',
  'safe2',
  'safe2-fill',
  'safe-fill',
  'salary-account',
  'save',
  'save2',
  'save2-fill',
  'save-fill',
  'saving-goals',
  'savings',
  'saving-term-deposits',
  'scan',
  'scissors',
  'scooter',
  'screwdriver',
  'sd-card',
  'sd-card-fill',
  'search',
  'search-heart',
  'search-heart-fill',
  'Security',
  'security-guide',
  'segmented-nav',
  'selected-bookmark',
  'send',
  'send-arrow-down',
  'send-arrow-down-fill',
  'send-arrow-up',
  'send-arrow-up-fill',
  'send-check',
  'send-check-fill',
  'send-dash',
  'send-dash-fill',
  'send-exclamation',
  'send-exclamation-fill',
  'send-fill',
  'send-plus',
  'send-plus-fill',
  'send-slash',
  'send-slash-fill',
  'send-x',
  'send-x-fill',
  'server',
  'set-pin',
  'setting',
  'shadows',
  'Shape arrows',
  'Shape letters and numbers',
  'Shapes',
  'share',
  'share-export',
  'share-fill',
  'shield',
  'shield-1',
  'shield-check',
  'shield-checkered',
  'shield-exclamation',
  'shield-fill',
  'shield-fill-1',
  'shield-fill-check',
  'shield-fill-exclamation',
  'shield-fill-minus',
  'shield-fill-plus',
  'shield-fill-x',
  'shield-lock',
  'shield-lock-fill',
  'shield-minus',
  'shield-plus',
  'shield-shaded',
  'shield-slash',
  'shield-slash-fill',
  'shield-x',
  'shift',
  'shift-fill',
  'shop',
  'shop-window',
  'show-eye',
  'shuffle',
  'signal',
  'sign-dead-end',
  'sign-dead-end-fill',
  'sign-do-not-enter',
  'sign-do-not-enter-fill',
  'sign-intersection',
  'sign-intersection-fill',
  'sign-intersection-side',
  'sign-intersection-side-fill',
  'sign-intersection-t',
  'sign-intersection-t-fill',
  'sign-intersection-y',
  'sign-intersection-y-fill',
  'sign-merge-left',
  'sign-merge-left-fill',
  'sign-merge-right',
  'sign-merge-right-fill',
  'sign-no-left-turn',
  'sign-no-left-turn-fill',
  'sign-no-parking',
  'sign-no-parking-fill',
  'sign-no-right-turn',
  'sign-no-right-turn-fill',
  'sign-out',
  'signpost',
  'signpost-2',
  'signpost-2-fill',
  'signpost-fill',
  'signpost-split',
  'signpost-split-fill',
  'sign-railroad',
  'sign-railroad-fill',
  'sign-stop',
  'sign-stop-fill',
  'sign-stop-lights',
  'sign-stop-lights-fill',
  'sign-turn-left',
  'sign-turn-left-fill',
  'sign-turn-right',
  'sign-turn-right-fill',
  'sign-turn-slight-left',
  'sign-turn-slight-left-fill',
  'sign-turn-slight-right',
  'sign-turn-slight-right-fill',
  'sign-yield',
  'sign-yield-fill',
  'sim',
  'sim-card',
  'sim-fill',
  'sim-slash',
  'sim-slash-fill',
  'sina-weibo',
  'skip-backward',
  'skip-backward-btn',
  'skip-backward-btn-fill',
  'skip-backward-circle',
  'skip-backward-circle-fill',
  'skip-backward-fill',
  'skip-backward-sm',
  'skip-end',
  'skip-end-btn',
  'skip-end-btn-fill',
  'skip-end-circle',
  'skip-end-circle-fill',
  'skip-end-fill',
  'skip-end-sm',
  'skip-forward',
  'skip-forward-btn',
  'skip-forward-btn-fill',
  'skip-forward-circle',
  'skip-forward-circle-fill',
  'skip-forward-fill',
  'skip-forward-sm',
  'skip-start',
  'skip-start-btn',
  'skip-start-btn-fill',
  'skip-start-circle',
  'skip-start-circle-fill',
  'skip-start-fill',
  'skip-start-sm',
  'skype',
  'slack',
  'slash',
  'slash-circle',
  'slash-circle-fill',
  'slash-lg',
  'slash-lg-1',
  'slash-square',
  'slash-square-fill',
  'sliders',
  'sliders2',
  'sliders2-vertical',
  'smart-saver',
  'smartwatch',
  'snapchat',
  'snow',
  'snow2',
  'snow3',
  'snp',
  'someone-else',
  'Sort and filter',
  'sort-alpha-down',
  'sort-alpha-down-alt',
  'sort-alpha-up',
  'sort-alpha-up-alt',
  'sort-down',
  'sort-down-alt',
  'sort-numeric-down',
  'sort-numeric-down-alt',
  'sort-numeric-up',
  'sort-numeric-up-alt',
  'sort-up',
  'sort-up-alt',
  'sound',
  'soundwave',
  'sourceforge',
  'speaker',
  'speaker-fill',
  'speedometer',
  'speedometer2',
  'spellcheck',
  'spending-limits',
  'spotify',
  'square',
  'square-fill',
  'square-half',
  'stack',
  'stack-overflow',
  'Star 8',
  'star',
  'star-favorite',
  'star-favorite-active',
  'star-fill',
  'star-half',
  'stars',
  'statement',
  'steam',
  'stickies',
  'stickies-fill',
  'sticky',
  'sticky-fill',
  'stop',
  'stop-btn',
  'stop-btn-fill',
  'stop-circle',
  'stop-circle-fill',
  'stop-fill',
  'stoplights',
  'stoplights-fill',
  'stop-sm',
  'stopwatch',
  'strava',
  'stripe',
  'subscript',
  'substack',
  'subtract',
  'success',
  'success-check',
  'success-circle',
  'suitcase',
  'suitcase2',
  'suitcase2-fill',
  'suitcase-fill',
  'suitcase-lg',
  'suitcase-lg-fill',
  'suit-club',
  'suit-club-fill',
  'suit-diamond',
  'suit-diamond-fill',
  'suit-heart',
  'suit-heart-fill',
  'suit-spade',
  'suit-spade-fill',
  'sun',
  'sun-fill',
  'sunglasses',
  'sunrise',
  'sunrise-fill',
  'sunset',
  'sunset-fill',
  'superscript',
  'Symbols',
  'symmetry-horizontal',
  'symmetry-vertical',
  'table',
  'tablet',
  'tablet-fill',
  'tablet-landscape',
  'tablet-landscape-fill',
  'tag',
  'tag-fill',
  'tags',
  'tags-fill',
  'taxi-front',
  'taxi-front-fill',
  'telegram',
  'telephone',
  'telephone-1',
  'telephone-fill',
  'telephone-fill-1',
  'telephone-forward',
  'telephone-forward-fill',
  'telephone-inbound',
  'telephone-inbound-fill',
  'telephone-minus',
  'telephone-minus-fill',
  'telephone-outbound',
  'telephone-outbound-fill',
  'telephone-plus',
  'telephone-plus-fill',
  'telephone-x',
  'telephone-x-fill',
  'tencent-qq',
  'terminal',
  'terminal-dash',
  'terminal-fill',
  'terminal-plus',
  'terminal-split',
  'terminal-x',
  'textarea',
  'textarea-resize',
  'textarea-t',
  'text-center',
  'text-indent-left',
  'text-indent-right',
  'text-left',
  'text-paragraph',
  'text-right',
  'text-wrap',
  'thermometer',
  'thermometer-half',
  'thermometer-high',
  'thermometer-low',
  'thermometer-snow',
  'thermometer-sun',
  'threads',
  'threads-fill',
  'three-dots',
  'three-dots-vertical',
  'three-user',
  'thunderbolt',
  'thunderbolt-fill',
  'ticket',
  'ticket-detailed',
  'ticket-detailed-fill',
  'ticket-fill',
  'ticket-perferated',
  'ticket-perferated-fill',
  'tiktok',
  'toggle2-off',
  'toggle2-on',
  'toggle-off',
  'toggle-on',
  'toggles',
  'toggles2',
  'toll-fee',
  'tools',
  'top-up',
  'tornado',
  'trace-transaction',
  'train-freight-front',
  'train-freight-front-fill',
  'train-front',
  'train-front-fill',
  'train-lightrail-front',
  'train-lightrail-front-fill',
  'transaction-history',
  'transfer',
  'transfer-money',
  'transfer-red',
  'translate',
  'transparency',
  'Transportation',
  'trash',
  'trash2',
  'trash2-fill',
  'trash3',
  'trash3-fill',
  'trash-fill',
  'trash-new',
  'Travel',
  'travel-ticket',
  'tree',
  'tree-fill',
  'trello',
  'triangle',
  'triangle-fill',
  'triangle-half',
  'trophy',
  'trophy-fill',
  'tropical-storm',
  'truck',
  'truck-flatbed',
  'truck-front',
  'truck-front-fill',
  'tsunami',
  'tuition',
  'tv',
  'tv-fill',
  'tv-license',
  'twitch',
  'twitter',
  'twitter-x',
  'type',
  'type-bold',
  'type-h1',
  'type-h2',
  'type-h3',
  'type-h4',
  'type-h5',
  'type-h6',
  'type-italic',
  'type-strikethrough',
  'type-underline',
  'Typography',
  'ubuntu',
  'UI and keyboard',
  'ui-checks',
  'ui-checks-grid',
  'ui-radios',
  'ui-radios-grid',
  'umbrella',
  'umbrella-fill',
  'uncheck',
  'unindent',
  'union',
  'unity',
  'universal-access',
  'universal-access-circle',
  'unlock',
  'unlock-fill',
  'up',
  'upc',
  'upc-scan',
  'upload',
  'upload-new',
  'usb',
  'usb-c',
  'usb-c-fill',
  'usb-drive',
  'usb-drive-fill',
  'usb-fill',
  'usb-micro',
  'usb-micro-fill',
  'usb-mini',
  'usb-mini-fill',
  'usb-plug',
  'usb-plug-fill',
  'usb-symbol',
  'user',
  'user-circle',
  'user-circle-gear',
  'user-new',
  'users-four',
  'valentine',
  'valentine2',
  'vector-pen',
  'view-list',
  'view-stacked',
  'vignette',
  'vimeo',
  'vinyl',
  'vinyl-fill',
  'virus',
  'virus2',
  'voicemail',
  'volume-down',
  'volume-down-fill',
  'volume-mute',
  'volume-mute-fill',
  'volume-off',
  'volume-off-fill',
  'volume-up',
  'volume-up-fill',
  'voucher',
  'vr',
  'wallet',
  'wallet-1',
  'wallet2',
  'wallet-fill',
  'wallet-menu',
  'wallet-new',
  'warning',
  'warning-alert',
  'warning-circle',
  'warning-diamond',
  'warning-new',
  'watch',
  'water',
  'Weather',
  'webcam',
  'webcam-fill',
  'wechat',
  'wet-signature',
  'whatsapp',
  'wifi',
  'wifi-1',
  'wifi-2',
  'wifi-high',
  'wifi-off',
  'wikipedia',
  'wind',
  'window',
  'window-dash',
  'window-desktop',
  'window-dock',
  'window-fullscreen',
  'window-plus',
  'windows',
  'window-sidebar',
  'window-split',
  'window-stack',
  'window-x',
  'wordpress',
  'workflow',
  'wrench',
  'wrench-adjustable',
  'wrench-adjustable-circle',
  'wrench-adjustable-circle-fill',
  'x',
  'xbox',
  'xcircle',
  'x-circle',
  'x-circle-fill',
  'x-diamond',
  'x-diamond-fill',
  'x-lg',
  'x-lg-1',
  'x-octagon',
  'x-octagon-fill',
  'x-sm',
  'x-square',
  'x-square-fill',
  'yelp',
  'yin-yang',
  'youtube',
  'zoom-in',
  'zoom-out',
  'undo',
  'redo',
];

export type IconName = (typeof ICON_NAMES)[number];
