// OverviewSection.tsx
import React from 'react';
import { useNode } from '@craftjs/core';
import { Card, Input } from 'antd';
import {
  HomeOutlined,
  UserOutlined,
  EnvironmentOutlined,
  ApartmentOutlined,
  DollarCircleOutlined,
} from '@ant-design/icons';

const OverviewSection = () => {
  const {
    connectors: { connect },
    props: {
      title,
      projectName,
      designer,
      type,
      location,
      phase,
      price,
      apartmentTypes,
      investment,
      themeColor,
    },
  } = useNode((node) => ({
    props: node.data.props,
  }));

  return (
    <div
      className="bg-[#F4F9F4] py-8 px-20 xl:px-44"
      ref={connect as React.LegacyRef<HTMLDivElement>}
    >
      <h2
        style={{ color: themeColor }}
        className="text-2xl md:text-4xl font-bold text-[#057E4B] py-4 mb-6"
      >
        {title}
      </h2>

      <div className="flex flex-col gap-6">
        <div className="flex flex-wrap gap-6 justify-between">
          <Card
            bordered={false}
            className="flex-1 shadow-md"
            headStyle={{ borderBottom: 'none' }}
            title={
              <div className="flex items-center gap-2">
                <HomeOutlined className="text-lg text-[#057E4B]" />
                <span className="font-semibold">Project Name</span>
              </div>
            }
          >
            {projectName}
          </Card>

          {/* Card 2 */}
          <Card
            bordered={false}
            className="flex-1 shadow-md bg-[#057E4B]  text-white"
            style={{
              backgroundColor: themeColor,
              color: '#FFFFFF',
            }}
            headStyle={{ borderBottom: 'none' }}
            title={
              <div className="flex items-center gap-2">
                <UserOutlined className="text-lg text-white" />
                <span className="font-semibold">Designer</span>
              </div>
            }
          >
            {designer}
          </Card>

          <Card
            bordered={false}
            className="flex-1 shadow-md"
            headStyle={{ borderBottom: 'none' }}
            title={
              <div className="flex items-center gap-2">
                <ApartmentOutlined className="text-lg text-[#057E4B]" />
                <span className="font-semibold">Type</span>
              </div>
            }
          >
            {type}
          </Card>

          <Card
            bordered={false}
            className="flex-1 shadow-md"
            headStyle={{ borderBottom: 'none' }}
            style={{
              backgroundColor: themeColor,
              color: '#FFFFFF',
            }}
            title={
              <div className="flex items-center  gap-2">
                <EnvironmentOutlined className="text-lg  text-[#057E4B]" />
                <span className="font-semibold">Location</span>
              </div>
            }
          >
            {location}
          </Card>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <Card
            bordered={false}
            className="shadow-md bg-[#057E4B] text-white"
            headStyle={{ borderBottom: 'none' }}
            style={{
              backgroundColor: themeColor,
              color: '#FFFFFF',
            }}
            title={
              <div className="flex items-center gap-2">
                <DollarCircleOutlined className="text-lg text-white" />
                <span className="font-semibold">Phase</span>
              </div>
            }
          >
            {phase}
            <br />
            <b>Expected Price:</b> {price}
          </Card>

          <Card
            bordered={false}
            className="shadow-md"
            headStyle={{ borderBottom: 'none' }}
            title={
              <div className="flex items-center gap-2">
                <ApartmentOutlined className="text-lg text-[#057E4B]" />
                <span className="font-semibold">Apartment types and sizes</span>
              </div>
            }
          >
            {apartmentTypes.split('\n').map((line, index) => (
              <div key={index}>{line}</div>
            ))}
          </Card>

          <Card
            bordered={false}
            className="shadow-md bg-[#057E4B]  text-white"
            style={{
              backgroundColor: themeColor,
              color: '#FFFFFF',
            }}
            headStyle={{ borderBottom: 'none' }}
            title={
              <div className="flex items-center gap-2">
                <DollarCircleOutlined className="text-lg text-white" />
                <span className="font-semibold">Investment and Ownership</span>
              </div>
            }
          >
            {investment}
          </Card>
        </div>
      </div>
    </div>
  );
};

OverviewSection.defaultProps = {
  title: 'Overview Information',
  projectName: 'Start New Green City',
  designer: 'Starland and partners',
  type: 'Luxury apartments',
  location: 'New Mexico',
  phase: 'First high-rise phase, limited release of 600 units',
  price: 'From 5000 USD/m²',
  themeColor: '#057E4B',
  apartmentTypes: `1 Bedroom: 56.56 – 60.99 m²
2 Bedrooms: 76.77 – 84.44 m²
3 Bedrooms: 113.54 – 143.74 m²
4 Bedrooms: 136.63 – 180.77 m²
Penthouse: 143.94 – 372.2 m²`,
  investment: `Attractive Offers: Special incentives and flexible payment options available
Legal Status: Ownership Certificate`,
};

// Craft.js configuration

const OverviewSectionSettings = () => {
  const {
    actions: { setProp },
    props: {
      title,
      projectName,
      designer,
      type,
      location,
      phase,
      price,
      apartmentTypes,
      investment,
    },
  } = useNode((node) => ({
    props: node.data.props,
  }));

  return (
    <div className="h-full">
      <h3 className="text-lg font-bold mb-4">Overview Section Settings</h3>

      <div className="mb-4">
        <label className="block mb-1 font-medium">Title</label>
        <Input
          value={title}
          onChange={(e) => setProp((props) => (props.title = e.target.value))}
        />
      </div>

      <div className="mb-4">
        <label className="block mb-1 font-medium">Project Name</label>
        <Input
          value={projectName}
          onChange={(e) =>
            setProp((props) => (props.projectName = e.target.value))
          }
        />
      </div>

      <div className="mb-4">
        <label className="block mb-1 font-medium">Designer</label>
        <Input
          value={designer}
          onChange={(e) =>
            setProp((props) => (props.designer = e.target.value))
          }
        />
      </div>

      <div className="mb-4">
        <label className="block mb-1 font-medium">Type</label>
        <Input
          value={type}
          onChange={(e) => setProp((props) => (props.type = e.target.value))}
        />
      </div>

      <div className="mb-4">
        <label className="block mb-1 font-medium">Location</label>
        <Input
          value={location}
          onChange={(e) =>
            setProp((props) => (props.location = e.target.value))
          }
        />
      </div>

      <div className="mb-4">
        <label className="block mb-1 font-medium">Phase</label>
        <Input
          value={phase}
          onChange={(e) => setProp((props) => (props.phase = e.target.value))}
        />
      </div>

      {/* Expected Price */}
      <div className="mb-4">
        <label className="block mb-1 font-medium">Expected Price</label>
        <Input
          value={price}
          onChange={(e) => setProp((props) => (props.price = e.target.value))}
        />
      </div>
      <div className="mb-4">
        <label className="block mb-1 font-medium">
          Investment and Ownership
        </label>
        <Input
          value={investment}
          onChange={(e) =>
            setProp((props) => (props.investment = e.target.value))
          }
        />
      </div>

      <div className="mb-4">
        <label className="block mb-1 font-medium">Apartment Types</label>
        <Input.TextArea
          value={apartmentTypes}
          autoSize={{ minRows: 4, maxRows: 10 }}
          onChange={(e) =>
            setProp((props) => (props.apartmentTypes = e.target.value))
          }
        />
      </div>
    </div>
  );
};
OverviewSection.craft = {
  props: OverviewSection.defaultProps,
  related: {
    settings: OverviewSectionSettings,
  },
  displayName: 'Overview Section',
};
export default OverviewSection;
