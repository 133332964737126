import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNode } from '@craftjs/core';
import { useMutation } from 'react-query';
import {
  fetchSuggestions,
  fetchCoordinates,
  fetchCurrentLocation,
} from '@/services/locationService';
import { Input, List, Button, Spin } from 'antd';
import { message } from 'antd';
import { EnvironmentOutlined } from '@ant-design/icons';
import debounce from 'lodash/debounce';
type LocationSectionProps = {
  heading: string;
  description: string;
  address: string;
  coordinates: { lat: number; lng: number };
  themeColor: string;
};

function LocationSection({
  heading,
  description,
  address,
  coordinates,
  themeColor,
}: LocationSectionProps) {
  const {
    connectors: { connect },
    id,
  } = useNode();

  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const mapInitialized = useRef(false);
  const mapRef = useRef<any>(null);
  const markerRef = useRef<any>(null);

  useEffect(() => {
    if (!iframeRef.current) return;

    const iframeDoc = iframeRef.current.contentDocument;
    if (!iframeDoc) return;

    if (!mapInitialized.current) {
      // Map initialization
      const styleLink = iframeDoc.createElement('link');
      styleLink.rel = 'stylesheet';
      styleLink.href =
        'https://unpkg.com/maplibre-gl@2.4.0/dist/maplibre-gl.css';
      iframeDoc.head.appendChild(styleLink);

      const script = iframeDoc.createElement('script');
      script.src = 'https://unpkg.com/maplibre-gl@2.4.0/dist/maplibre-gl.js';
      script.onload = () => {
        const maplibregl = (iframeDoc.defaultView as any).maplibregl;

        let mapContainer = iframeDoc.getElementById('map-container');
        if (!mapContainer) {
          mapContainer = iframeDoc.createElement('div');
          mapContainer.id = 'map-container';
          mapContainer.style.height = '100%';
          mapContainer.style.width = '100%';
          iframeDoc.body.appendChild(mapContainer);
        }

        // Initialize the map
        const map = new maplibregl.Map({
          container: mapContainer,
          style:
            'https://basemaps.cartocdn.com/gl/positron-gl-style/style.json',
          center: [coordinates.lng, coordinates.lat],
          zoom: 13,
        });

        // Create and store marker
        const marker = new maplibregl.Marker()
          .setLngLat([coordinates.lng, coordinates.lat])
          .addTo(map);

        // Store references
        mapRef.current = map;
        markerRef.current = marker;
        mapInitialized.current = true;
      };

      iframeDoc.head.appendChild(script);
    } else {
      // Map already initialized: update marker and center
      if (mapRef.current && markerRef.current) {
        markerRef.current.setLngLat([coordinates.lng, coordinates.lat]);
        mapRef.current.setCenter([coordinates.lng, coordinates.lat]);
      }
    }
  }, [coordinates]);

  // useEffect(() => {
  //   if (mapInitialized.current && mapRef.current && markerRef.current) {
  //     mapRef.current.setCenter([coordinates.lng, coordinates.lat]);
  //     markerRef.current.setLngLat([coordinates.lng, coordinates.lat]);
  //   }
  // }, [coordinates]);

  return (
    <div
      className="bg-[#057E4B] text-white py-16 px-6 md:px-16 lg:px-24 relative z-2"
      ref={connect as React.LegacyRef<HTMLDivElement>}
      id={id}
      style={{
        backgroundColor: themeColor,
      }}
    >
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center">
        <div className="w-full md:w-1/2">
          <h2 className="text-3xl md:text-6xl font-extrabold mb-6 leading-relaxed whitespace-normal">
            {heading}
          </h2>
          <p className="text-lg leading-relaxed">{description}</p>
        </div>
        <div className="w-full md:w-1/2">
          <iframe
            ref={iframeRef}
            style={{
              height: '400px',
              width: '100%',
              border: 'none',
              borderRadius: '8px',
            }}
            title="Map"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

LocationSection.defaultProps = {
  heading: 'A Unique Diamond Location Connecting Every Destination',
  description:
    "Located in the prime 'diamond' position of ABC ward, District 1, connecting the main artery to New Mexico City's center and the Roswell highway, Starlake - New Green City enjoys the privileged convenience of mobility, making it easy for residents to access destinations throughout the city. With its advantageous location along the Santa Fe National Forest, Starlake not only offers diverse landscapes and fresh air but also brings prosperity and good fortune to its residents.",
  address: 'District 1, Ho Chi Minh City, Vietnam',
  coordinates: {
    lat: 10.77584,
    lng: 106.70089,
  },
  themeColor: '#057E4B',
};

export function LocationSectionSettings() {
  const {
    actions: { setProp },
    heading,
    description,
    address,
    coordinates,
  } = useNode((node) => ({
    heading: node.data.props.heading,
    description: node.data.props.description,
    address: node.data.props.address,
    coordinates: node.data.props.coordinates,
  }));

  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const { mutate: getSuggestions } = useMutation(fetchSuggestions, {
    onSuccess: (data) => {
      setSuggestions(data);
      setDropdownVisible(true);
    },
    onError: (error) => {
      console.error('Error fetching suggestions:', (error as Error).message);
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchSuggestions = useCallback(
    debounce((query: string) => {
      getSuggestions(query);
    }, 500),
    []
  );

  const { mutate: getCoordinates } = useMutation(fetchCoordinates, {
    onSuccess: ({ lat, lng }, selectedAddress) => {
      setProp((props) => {
        props.address = selectedAddress;
        props.coordinates = { lat, lng };
      });
      message.success('Location updated successfully!');
    },
    onError: (error) => {
      console.error('Error fetching coordinates:', error);
    },
  });

  const { mutate: getCurrentLocation, isLoading: isLoadingLocation } =
    useMutation(fetchCurrentLocation, {
      onSuccess: ({ address, coordinates }) => {
        setProp((props) => {
          props.address = address;
          props.coordinates = coordinates;
        });
        message.success('Current location set successfully!');
      },
      onError: (error) => {
        console.error('Error fetching suggestions:', (error as Error).message);
      },
    });

  return (
    <div>
      <div className="mb-5">
        <label className="block mb-2 font-bold text-gray-700">Heading</label>
        <Input
          value={heading}
          onChange={(e) =>
            setProp((props: { heading: string }) => {
              props.heading = e.target.value;
            })
          }
        />
      </div>

      <div className="mb-5">
        <label className="block mb-2 font-bold text-gray-700">
          Description
        </label>
        <Input.TextArea
          autoSize={{ minRows: 4, maxRows: 10 }}
          value={description}
          onChange={(e) =>
            setProp((props: { description: string }) => {
              props.description = e.target.value;
            })
          }
        />
      </div>

      <div className="mb-5">
        <label className="block mb-2 font-bold text-gray-700">Address</label>
        <Input
          value={address}
          onChange={(e) => {
            const newAddress = e.target.value;
            setProp((props: { address: string }) => {
              props.address = newAddress;
            });
            debouncedFetchSuggestions(newAddress);
          }}
          onBlur={() => setTimeout(() => setDropdownVisible(false), 200)}
          suffix={
            <Button
              onClick={() => getCurrentLocation()}
              type="primary"
              disabled={isLoadingLocation}
              className={`rounded-full  text-white ${
                isLoadingLocation
                  ? 'bg-gray-400 cursor-not-allowed'
                  : 'bg-blue-500 hover:bg-blue-600'
              }`}
            >
              {isLoadingLocation ? (
                <Spin size="small" />
              ) : (
                <EnvironmentOutlined />
              )}
            </Button>
          }
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          className="text-lg rounded-full border border-gray-300 shadow-md focus:border-blue-500 focus:ring focus:ring-blue-200"
        />
        {isDropdownVisible && (
          <List
            bordered
            dataSource={suggestions}
            renderItem={(item) => (
              <List.Item
                onClick={() => getCoordinates(item)}
                className="cursor-pointer hover:bg-gray-100 flex items-center space-x-2"
              >
                <span>
                  <EnvironmentOutlined
                    className="mr-2"
                    style={{ color: '#007bff' }}
                  />
                  {item}
                </span>
              </List.Item>
            )}
            className="mt-2"
          />
        )}
      </div>
    </div>
  );
}
LocationSection.craft = {
  props: LocationSection.defaultProps,
  related: {
    settings: LocationSectionSettings,
  },
  displayName: 'Location Section',
};

export default LocationSection;
