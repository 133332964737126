import React from 'react';
import { Button, Carousel, Flex } from 'antd';
import { useNode } from '@craftjs/core';
import AssetUpload from '@/components/AssetUpload';

type PlaceSectionProps = {
  images: { url: string; name: string }[];
  title: string;
  subtitle: string;
};

function PlaceSection({ images, title, subtitle }: PlaceSectionProps) {
  const {
    connectors: { connect },
    id,
  } = useNode();

  return (
    <div
      className="py-16 px-6 min-h-[400px] max-h-[600px] text-center"
      ref={connect as React.LegacyRef<HTMLDivElement>}
      id={id}
    >
      <h2 className="text-2xl md:text-3xl font-bold mb-8">{title}</h2>
      <p className="text-lg md:text-xl text-gray-600 mb-8">{subtitle}</p>
      <Carousel
        dots={true}
        slidesToShow={3}
        slidesToScroll={1}
        autoplay
        centerMode={true}
        centerPadding="80px"
        autoplaySpeed={3000}
        className="rounded-lg overflow-hidden"
        responsive={[
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              centerPadding: '40px',
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              centerPadding: '60px',
            },
          },
        ]}
      >
        {images.map((image, index) => (
          <div key={index} className="px-4">
            <img
              className="h-[320px] w-full object-cover rounded-md"
              src={image.url}
              alt={image.name}
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
}
PlaceSection.defaultProps = {
  images: [
    { url: '/real-estate/place-1.png', name: 'Place 1' },
    { url: '/real-estate/place-2.png', name: 'Place 2' },
    { url: '/real-estate/place-3.jpg', name: 'Place 3' },
    { url: '/real-estate/place-4.webp', name: 'Place 4' },
  ],
  title: 'A Place that Celebrates Every Lifestyle',
  subtitle: 'Discover the harmony of living with nature and community.',
};

function PlaceSectionSettings() {
  const {
    actions: { setProp },
    images,
    title,
    subtitle,
  } = useNode((node) => ({
    images: node.data.props.images,
    title: node.data.props.title,
    subtitle: node.data.props.subtitle,
  }));

  return (
    <div>
      {/* Title */}
      <div className="mb-5">
        <label className="block mb-2 font-bold text-gray-700">Title</label>
        <textarea
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          value={title}
          onChange={(e) =>
            setProp(
              (props: { title: string }) => (props.title = e.target.value)
            )
          }
        />
      </div>

      {/* Subtitle */}
      <div className="mb-5">
        <label className="block mb-2 font-bold text-gray-700">Subtitle</label>
        <textarea
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          value={subtitle}
          onChange={(e) =>
            setProp(
              (props: { subtitle: string }) => (props.subtitle = e.target.value)
            )
          }
        />
      </div>

      {/* Images */}
      <div className="mb-5">
        <label className="block mb-2 font-bold text-gray-700">Images</label>
        <div className="space-y-4">
          {images.map((image: { url: string; name: string }, index: number) => (
            <Flex key={index} align="center" justify="space-between">
              <span>{image.name}</span>
              <AssetUpload
                onSelect={(file) => {
                  setProp(
                    (props: { images: { url: string; name: string }[] }) => {
                      props.images[index] = { url: file.url, name: file.name };
                    },
                    1000
                  );
                }}
              >
                <Button type="link" className="p-0">
                  CHANGE
                </Button>
              </AssetUpload>
              <Button
                type="link"
                danger
                className="p-0"
                onClick={() => {
                  setProp(
                    (props: { images: { url: string; name: string }[] }) =>
                      (props.images = props.images.filter(
                        (_, i) => i !== index
                      )),
                    1000
                  );
                }}
              >
                REMOVE
              </Button>
            </Flex>
          ))}
        </div>
        <AssetUpload
          onSelect={(file) => {
            setProp(
              (props: { images: { url: string; name: string }[] }) =>
                (props.images = [
                  ...props.images,
                  { url: file.url, name: file.name },
                ]),
              1000
            );
          }}
        >
          <Button type="primary" className="mt-4">
            ADD IMAGE
          </Button>
        </AssetUpload>
      </div>
    </div>
  );
}

PlaceSection.craft = {
  props: PlaceSection.defaultProps,
  related: {
    settings: PlaceSectionSettings,
  },
  displayName: 'Place Section',
};

export default PlaceSection;
