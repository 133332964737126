import { useNode } from '@craftjs/core';
import { Button, Flex, Collapse } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import styled from "@emotion/styled";
import AssetUpload from '@/components/AssetUpload';

const StyledCollapse = styled(Collapse)`
    .ant-collapse-header {
        padding: 8px !important;
    }

    .ant-collapse-header .ant-collapse-expand-icon {
        display: none !important;
    }
`;

const TipItem = ({ item, index, onChangeItem }) => {
  return (<Flex vertical gap="middle">
    <div>
      <label className="block font-bold text-gray-700">Title</label>
      <textarea
        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
        placeholder="Title"
        value={item.title}
        onChange={(e) => onChangeItem(index, 'title', e.target.value)}
      />
    </div>
    <div>
      <label className="block font-bold text-gray-700">Background</label>
      <Flex align="center" justify="space-between">
        <span>{item.image?.name || 'No image selected'}</span>
        <AssetUpload
          onSelect={(file) => onChangeItem(index, 'image', file)}
        >
          <Button type="link">
            {item.image?.url ? 'CHANGE' : 'SELECT'} IMAGE
          </Button>
        </AssetUpload>
      </Flex>
    </div>
  </Flex>)
}

const TravelTipsSetting = () => {
  const {
    actions: { setProp },
    title,
    subtitle,
    description,
    listItem
  } = useNode((node) => ({
    title: node.data.props.title,
    subtitle: node.data.props.subtitle,
    description: node.data.props.description,
    listItem: node.data.props.listItem
  }));

  const handleTitleChange = (value: string) => {
    setProp((props: any) => (props.title = value), 1000);
  };

  const handleSubtitleChange = (value: string) => {
    setProp((props: any) => (props.subtitle = value), 1000);
  };

  const handleDescriptionChange = (value: string) => {
    setProp((props: any) => (props.description = value), 1000);
  };

  const handleChangeItem = (index: number, field: string, value: any) => {
    setProp((props: any) => {
      const newListItem = [...props.listItem];
      if (field === 'image') {
        newListItem[index].image = { url: value.url, name: value.name };
      } else {
        newListItem[index][field] = value;
      }
      props.listItem = newListItem;
    }, 1000);
  };

  const handleAddItem = () => {
    setProp((props: any) => {
      const newListItem = [
        ...props.listItem,
        {
          title: 'New Tip',
          image: { url: "/images/IIIustrations/wellcome.svg", name: "wellcome" }
        }
      ];
      props.listItem = newListItem;
    }, 1000);
  };

  const handleRemoveItem = (index: number) => {
    setProp((props: any) => {
      const newListItem = [...props.listItem];
      newListItem.splice(index, 1);
      props.listItem = newListItem;
    }, 1000);
  };

  return (
    <Flex vertical gap="middle">
      <div>
        <label className="block font-bold text-gray-700">Title</label>
        <textarea
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          placeholder="Title"
          value={title}
          onChange={(e) => handleTitleChange(e.target.value)}
        />
      </div>

      <div>
        <label className="block font-bold text-gray-700">Subtitle</label>
        <textarea
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          placeholder="Subtitle"
          value={subtitle}
          onChange={(e) => handleSubtitleChange(e.target.value)}
        />
      </div>

      <div>
        <label className="block font-bold text-gray-700">Description</label>
        <textarea
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          placeholder="Description"
          value={description}
          onChange={(e) => handleDescriptionChange(e.target.value)}
        />
      </div>



      <StyledCollapse
        defaultActiveKey={['0']}
        items={listItem.map((item, index) => ({
          key: index,
          label: (
            <Flex align="center" justify="space-between">
              <span>Tip {index + 1}</span>
              <Button
                type="text"
                danger
                icon={<DeleteOutlined />}
                onClick={(e) => {
                  e.stopPropagation();
                  handleRemoveItem(index);
                }}
              />
            </Flex>
          ),
          children: (
            <TipItem
              item={item}
              index={index}
              onChangeItem={handleChangeItem}
            />
          ),
        }))}
      />

      <Button
        type="dashed"
        onClick={handleAddItem}
        block
        icon={<PlusOutlined />}
      >
        Add Tip
      </Button>

    </Flex>
  );
};

export default TravelTipsSetting;
