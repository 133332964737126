import { Collapse } from "antd";
import styled from "styled-components";

export const StyledCollapse = styled(Collapse)`
    .ant-collapse-header {
        padding: 8px !important;
    }

    .ant-collapse-header .ant-collapse-expand-icon {
        display: none !important;
    }
`;