import AssetUpload from "@/components/AssetUpload";
import { useNode } from "@craftjs/core";
import { Button, Flex } from "antd";

const TravelTourSetting = () => {
    const {
        actions: { setProp },
        subtitle,
        title,
        firstContent,
        secondContent,
    } = useNode((node) => ({
        id: node.data.props.id,
        subtitle: node.data.props.subtitle,
        title: node.data.props.title,
        firstContent: node.data.props.firstContent,
        secondContent: node.data.props.secondContent,
    }));
    return "Coming soon";
    // TODO: editor
    return <Flex vertical>
        {/* Subtitle */}
        <div className="mb-5">
            <label className="block mb-2 font-bold text-gray-700">Subtitle</label>
            <textarea
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                value={subtitle}
                onChange={(e) =>
                    setProp(
                        (props: { subtitle: string }) => (props.subtitle = e.target.value)
                    )
                }
            />
        </div>

        {/* Title */}
        <div className="mb-5">
            <label className="block mb-2 font-bold text-gray-700">Title</label>
            <textarea
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                value={title}
                onChange={(e) =>
                    setProp(
                        (props: { title: string }) => (props.title = e.target.value)
                    )
                }
            />
        </div>

        {/* First Content */}
        <div className="mb-5">
            <label className="block mb-2 font-bold text-gray-700">Title first content</label>
            <textarea
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                value={firstContent?.title}
                onChange={(e) =>
                    setProp(
                        (props: { title: string }) => (props.title = e.target.value)
                    )
                }
            />
        </div>

        {/* Image */}
        <div className="mb-5">
            <label className="block mb-2 font-bold text-gray-700">Image first content</label>
            <Flex className="w-full" align="center" justify="space-between">
                <span>{firstContent?.image?.name}</span>
                <AssetUpload
                    onSelect={(file) => {
                        setProp(
                            (props: {
                                firstContent: {
                                    image: { url: string; name: string };
                                };
                            }) =>
                            (props.firstContent = {
                                image: { url: file.url, name: file.name },
                            }),
                            1000
                        );
                    }}
                >
                    <Button type="link" className="p-0">
                        CHANGE
                    </Button>
                </AssetUpload>
            </Flex>
        </div>
    </Flex>;
};

export default TravelTourSetting;
