import config from '@/config';
import { getAssets } from '@/services/assetsManagementService';
import { SearchOutlined } from '@ant-design/icons';
import { Checkbox, Flex, Image, Table, Typography } from 'antd';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { GTGSButton, GTGSIcon, GTGSInput } from '../GTGS';
import UploadButton from './UploadButton';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

const AssetList = ({ onSelect, isMultiple = false }) => {
  const [checkedIds, setCheckedIds] = useState<string[]>([]);
  const { data } = useQuery(
    'assets',
    () => getAssets({ page: 1, pageSize: 100 }),
    {
      select: (data) =>
        data.files.map((file) => ({
          ...file,
          url: `${config.assetsUrl}/${file.key}`,
        })),
    }
  );

  const onCheck = (e: CheckboxChangeEvent, id: string) => {
    if (!e.target.checked) {
      setCheckedIds(checkedIds.filter(checkedId => checkedId !== id));
      return;
    }
    
    if (isMultiple) {
      setCheckedIds(pre => ([...pre, id]));
    } else {
      setCheckedIds([id]);
    }
  };

  const columns = [
    {
      title: 'IMAGE',
      dataIndex: 'url',
      key: 'url',
      render: (url, file) => (
        <Image
          src={url}
          alt="asset"
          width={100}
          height={100}
          className={`rounded-md border-[2px] border-solid ${checkedIds.includes(file.id) ? 'border-blue-500' : 'border-gray-100'}`}
          preview={{
            maskClassName: 'rounded-md',
            mask: (
              <>
                <div
                  className="absolute top-1 left-1"
                  onClick={(e) => e.stopPropagation()}
                >
                  <Checkbox
                    className="w-5 h-5"
                    onChange={(e) => onCheck(e, file.id)}
                    checked={checkedIds.includes(file.id)}
                  />
                </div>
                Preview
              </>
            ),
          }}
        />
      ),
    },
    {
      title: 'NAME AND URL',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => (
        <Flex vertical>
          <strong>{record.name}</strong>
          <div>{record.url}</div>
        </Flex>
      ),
    },
    {
      title: 'IMAGE TYPE',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'UPDATED DATE',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: string) => (
        <Flex vertical>
          <strong>{new Date(createdAt).toLocaleDateString('en-US')}</strong>
          <Typography.Text>
            {new Date(createdAt).toLocaleTimeString('en-US', {
              hour: '2-digit',
              minute: '2-digit',
              hour12: true,
            })}
          </Typography.Text>
        </Flex>
      ),
    },
    {
      title: 'CREATED DATE',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (updatedAt: string) => (
        <Flex vertical>
          <strong>{new Date(updatedAt).toLocaleDateString('en-US')}</strong>
          <Typography.Text>
            {new Date(updatedAt).toLocaleTimeString('en-US', {
              hour: '2-digit',
              minute: '2-digit',
              hour12: true,
            })}
          </Typography.Text>
        </Flex>
      ),
    },
  ];

  return (
    <>
      <Flex className="w-full my-4" align="center" justify="space-between">
        <Flex align="center" gap={8}>
          <GTGSInput
            placeholder="Search"
            suffix={<SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />}
            className="w-full max-w-[221px]"
          />
          <GTGSButton
            icon={<GTGSIcon name="image" size={16} fill="#fff" />}
            type="primary"
            hidden={checkedIds.length === 0}
            onClick={() => {
              if (isMultiple) {
                const selectedFiles = data.filter(item => checkedIds.includes(item.id));
                onSelect(selectedFiles);
              } else {
                onSelect(data.find((item) => item.id === checkedIds[0]));
              }
              setCheckedIds([]);
            }}
          >
            Select {isMultiple && checkedIds.length > 0 ? `(${checkedIds.length})` : ''}
          </GTGSButton>
        </Flex>
        <UploadButton />
      </Flex>

      <Table
        dataSource={data}
        columns={columns}
        rowKey="id"
        pagination={{
          pageSize: 3,
          position: ['bottomCenter'],
        }}
      />
    </>
  );
};

export default AssetList;
