import { Modal } from 'antd';
import React from 'react';
import AssetList from './AssetList';

const AssetUpload = ({ children, onSelect, isMultiple = false }) => {
  const [openUpload, setOpenUpload] = React.useState(false);

  const handleSelect = (file) => {
    onSelect(file);
    setOpenUpload(false);
  };

  return (
    <>
      {children &&
        React.cloneElement(children, {
          onClick: () => setOpenUpload(true),
        })}
      <Modal
        onCancel={() => {
          setOpenUpload(false);
        }}
        open={openUpload}
        title="Assets"
        footer={null}
        centered
        width={1200}
      >
        <AssetList onSelect={handleSelect} isMultiple={isMultiple} />
      </Modal>
    </>
  );
};

export default AssetUpload;
