import { useNode } from '@craftjs/core';
import { Button, Flex, AutoComplete } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { StyledCollapse } from '../../travelStyles';
import { GTGSIcon } from '@/components/GTGS';
import { ICON_NAMES } from '@/components/GTGS/GTGSIcon/GTGSIcon.types';

const BenefitItem = ({ benefit, handleChangeItem, index }) => {
    return (
        <Flex vertical gap="middle">
            <div>
                <label className="block font-bold text-gray-700">Title</label>
                <textarea
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Title"
                    value={benefit.title}
                    onChange={(e) => handleChangeItem(index, 'title', e.target.value)}
                />
            </div>

            <div>
                <label className="block font-bold text-gray-700">Content</label>
                <textarea
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Content"
                    value={benefit.content}
                    onChange={(e) => handleChangeItem(index, 'content', e.target.value)}
                />
            </div>

            <div>
                <label className="block font-bold text-gray-700">Icon</label>
                <AutoComplete
                allowClear
                    className="w-full"
                    placeholder="Select an icon"
                    value={benefit.iconName}
                    onChange={(value) => handleChangeItem(index, 'iconName', value)}
                    options={ICON_NAMES.map((icon) => ({
                        value: icon,
                        label: (
                            <div className="flex items-center gap-2">
                                <GTGSIcon name={icon} size={16} />
                                <span>{icon}</span>
                            </div>
                        ),
                    }))}
                    filterOption={(inputValue, option) =>
                        option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    }
                />
            </div>
        </Flex>
    );
};

const TravelBenefitSetting = () => {
    const { actions: { setProp }, title, description, listItem } = useNode((node) => ({
        props: node.data.props,
        title: node.data.props.title,
        description: node.data.props.description,
        listItem: node.data.props.listItem
    }));

    const handleRemoveItem = (index) => {
        setProp((props: { listItem: { title: string; description: string }[] }) => {
            const newListItem = [...(props.listItem || [])];
            newListItem.splice(index, 1);
            props.listItem = newListItem;
        }, 1000);
    };

    const handleAddItem = () => {
        setProp((props: { listItem: { title: string; description: string }[] }) => {
            const newListItem = [...(props.listItem || []), { title: 'New Title', description: 'New Description' }];
            props.listItem = newListItem;
        }, 1000);
    };

    const handleChangeItem = (index: number, field: string, value: string) => {
        setProp((props: { listItem: { title: string; description: string }[] }) => {
            const newListItem = [...(props.listItem || [])];
            newListItem[index][field] = value;
            props.listItem = newListItem;
        }, 1000);
    };

    const handleTitleChange = (value: string) => {
        setProp((props) => {
            props.title = value;
        }, 1000);
    };

    const handleDescriptionChange = (value: string) => {
        setProp((props) => {
            props.description = value;
        }, 1000);
    };

    return (
        <Flex vertical gap="middle">
            <div className="mb-5">
                <label className="block font-bold text-gray-700 mb-2">Title</label>
                <textarea
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Title"
                    value={title}
                    onChange={(e) => handleTitleChange(e.target.value)}
                />

                <label className="block font-bold text-gray-700 mb-2">Description</label>
                <textarea
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Description"
                    value={description}
                    onChange={(e) => handleDescriptionChange(e.target.value)}
                />

                <label className="block font-bold text-gray-700 mb-2">Benefits</label>

                <StyledCollapse
                    className="mb-2"
                    defaultActiveKey={['0']}
                    items={listItem.map((benefit, index) => ({
                        key: index.toString(),
                        label: (
                            <Flex justify="space-between" align="center">
                                <span className="font-semibold text-gray-600">Benefit {index + 1}</span>
                                <Button
                                    danger
                                    type="link"
                                    icon={<DeleteOutlined />}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveItem(index);
                                    }}
                                />
                            </Flex>
                        ),
                        children: (
                            <BenefitItem
                                benefit={benefit}
                                index={index}
                                handleChangeItem={handleChangeItem}
                            />
                        ),
                    }))}
                />

                <Button
                    className="w-full"
                    type="dashed"
                    icon={<PlusOutlined />}
                    onClick={handleAddItem}
                >
                    Add Benefit
                </Button>
            </div>
        </Flex>
    );
};

export default TravelBenefitSetting;
