import { useNode } from '@craftjs/core';
import { Form, Input, Button, Card } from 'antd';
import React from 'react';
import { SwapOutlined } from '@ant-design/icons';
type ContactSectionProps = {
  price: string;
  incentives: string;
  description: string;
  buttonLabel: string;

  swapFormAndText?: boolean;
};

const ContactSection = ({
  price,
  incentives,
  description,
  buttonLabel,

  swapFormAndText = false,
}: ContactSectionProps) => {
  const {
    connectors: { connect },
    id,
  } = useNode();
  return (
    <div
      ref={connect as React.LegacyRef<HTMLDivElement>}
      id={id}
      className="flex flex-col md:flex-row justify-between gap-8 py-16 md:py-28 px-8 md:px-18 xl:px-36 bg-white shadow-md rounded-lg w-full md:w-screen"
    >
      <div className={`flex-1 ${swapFormAndText ? 'order-2' : 'order-1'}`}>
        <div className="flex flex-col md:flex-row gap-4 md:gap-8 mb-6">
          <div>
            <p className="text-gray-500 text-lg md:text-2xl">
              Prices starting from
            </p>
            <h2 className="text-2xl md:text-3xl font-bold text-black">
              {price}
            </h2>
          </div>
          <div>
            <p className="text-gray-500 text-lg md:text-2xl">
              Payment incentives
            </p>
            <h2 className="text-2xl md:text-3xl font-bold text-black">
              {incentives}
            </h2>
          </div>
        </div>

        <div className="p-4 md:p-8 bg-[#F9FBFE] rounded-lg shadow-sm leading-relaxed text-sm md:text-base">
          {description.split('\n').map((line, index) => (
            <div key={index}>{line}</div>
          ))}
        </div>
      </div>

      <div className={`flex-1 ${swapFormAndText ? 'order-1' : 'order-2'}`}>
        <Card className="p-4 md:p-8 rounded-lg shadow-xl w-full">
          <h2 className="text-xl md:text-2xl font-bold text-black mb-4">
            Contact Us For More Information
          </h2>
          <p className="text-gray-700 text-sm md:text-base mb-4">
            To save time exploring the Starlake apartments and to book during
            the first phase at the best price, please leave your information
            below or contact the hotline to receive detailed information from
            our specialist.
          </p>

          <Form layout="vertical">
            <Form.Item label="Fullname" name="fullname">
              <Input
                placeholder="Enter your fullname"
                className="rounded-md py-2"
              />
            </Form.Item>

            <Form.Item label="Email" name="email">
              <Input
                placeholder="Enter your email"
                className="rounded-md py-2"
              />
            </Form.Item>

            <Form.Item label="Phone number" name="phone">
              <Input
                placeholder="Enter your phone number"
                className="rounded-md py-2"
              />
            </Form.Item>

            <Form.Item>
              <button className="w-full mt-4 rounded-md py-3 text-white bg-[#057E4B]">
                {buttonLabel}
              </button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
};

ContactSection.defaultProps = {
  price: '5000USD/m²',
  incentives: '16%',
  description: `The only luxury apartments in New Mexico designed by Starland & Partners, globally renowned architectural brands
First phase of high-rise units available for sale, limited to only 600 units
Expected selling price from 1000/m²
Sophisticated design: Spacious apartments with optimized panoramic views
Attractive policies: Special offers with flexible payment plans
Sold legal framework: Long-term ownership with pink book (and use right certificate)
Handed over fully finished, expected in 2026
Diverse apartment types:
1 Bedroom: 56.56 – 60.99 m²
2 Bedrooms: 76.77 – 84.44 m²
3 Bedrooms: 113.54 – 143.74 m²
4 Bedrooms: 136.63 – 180.77 m²
Penthouse: 143.94 – 372.2 m²`,
  buttonLabel: 'Send',

  swapFormAndText: false,
};

function ContactSectionSettings() {
  const {
    actions: { setProp },
    price,
    incentives,
    description,
    buttonLabel,

    swapFormAndText,
  } = useNode((node) => ({
    price: node.data.props.price,
    incentives: node.data.props.incentives,
    description: node.data.props.description,
    buttonLabel: node.data.props.buttonLabel,

    swapFormAndText: node.data.props.swapFormAndText,
  }));

  return (
    <div>
      <div className="mb-5">
        <label className="block mb-2 font-bold text-gray-700">Price</label>
        <Input
          type="text"
          className="w-full px-3 py-2 border border-gray-300 rounded-md"
          value={price}
          onChange={(e) =>
            setProp(
              (props: { price: string }) => (props.price = e.target.value)
            )
          }
        />
      </div>

      <div className="mb-5">
        <label className="block mb-2 font-bold text-gray-700">
          Payment Incentives
        </label>
        <Input
          type="text"
          className="w-full px-3 py-2 border border-gray-300 rounded-md"
          value={incentives}
          onChange={(e) =>
            setProp(
              (props: { incentives: string }) =>
                (props.incentives = e.target.value)
            )
          }
        />
      </div>

      <div className="mb-5">
        <label className="block mb-2 font-bold text-gray-700">
          Description
        </label>
        <Input.TextArea
          autoSize={{ minRows: 10, maxRows: 10 }}
          className="w-full px-3 py-2 border border-gray-300 rounded-md"
          value={description}
          onChange={(e) =>
            setProp(
              (props: { description: string }) =>
                (props.description = e.target.value)
            )
          }
        />
      </div>

      <div className="mb-5">
        <label className="block mb-2 font-bold text-gray-700">
          Button Label
        </label>
        <Input
          type="text"
          className="w-full px-3 py-2 border border-gray-300 rounded-md"
          value={buttonLabel}
          onChange={(e) =>
            setProp(
              (props: { buttonLabel: string }) =>
                (props.buttonLabel = e.target.value)
            )
          }
        />
      </div>

      <div className="mb-5">
        <label className="block mb-2 font-bold text-gray-700">
          Reverse Items
        </label>
        <button
          className={`p-2 border rounded-md flex items-center justify-center `}
          onClick={() =>
            setProp(
              (props: { swapFormAndText: boolean }) =>
                (props.swapFormAndText = !swapFormAndText)
            )
          }
        >
          <SwapOutlined
            className={`text-2xl ${
              swapFormAndText ? 'text-blue-500' : 'text-gray-500'
            }`}
          />
        </button>
      </div>
    </div>
  );
}

ContactSection.craft = {
  props: ContactSection.defaultProps,
  related: {
    settings: ContactSectionSettings,
  },
  displayName: ' Contact Section',
};

export default ContactSection;
