export const fetchSuggestions = async (query: string) => {
  const response = await fetch(
    `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(
      query
    )}&addressdetails=1&limit=5`
  );
  const data = await response.json();
  return data.map((item: any) => item.display_name);
};

export const fetchCoordinates = async (selectedAddress: string) => {
  const response = await fetch(
    `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(
      selectedAddress
    )}&addressdetails=1`
  );
  const data = await response.json();
  if (data && data[0]) {
    return { lat: parseFloat(data[0].lat), lng: parseFloat(data[0].lon) };
  }
  throw new Error('No coordinates found');
};

export const fetchCurrentLocation = async (): Promise<{
  address: string;
  coordinates: { lat: number; lng: number };
}> => {
  if (!navigator.geolocation) {
    throw new Error('Geolocation is not supported by this browser.');
  }

  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      async ({ coords: { latitude, longitude } }) => {
        try {
          const response = await fetch(
            `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&addressdetails=1`
          );
          const data = await response.json();
          resolve({
            address: data.display_name,
            coordinates: { lat: latitude, lng: longitude },
          });
        } catch (error) {
          reject(new Error('Error fetching current location address'));
        }
      },
      (error) => {
        reject(new Error('Error getting current location'));
      }
    );
  });
};
