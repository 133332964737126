import { GTGSIcon } from '@/components/GTGS';
import ChatButton from '@/pages/PageBuilder/components/live-chat/ChatButton';
import { useNode } from '@craftjs/core';
import React from 'react';
import { TravelHeaderProps } from '../types';
import TravelHeaderSetting from './settings/TravelHeaderSetting';

function TravelHeader({ backgroundImage, logo, title, subtitle, buttonLabel, buttonLink, themeColor }: TravelHeaderProps) {
  const handleLoginClick = () => {
    window.location.href = buttonLink;
  };
  const {
    connectors: { connect },
    id,
  } = useNode();
  
  const scrollToSection = (sectionId) => {
    const targetSection = document.getElementById(sectionId);
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <header
        ref={connect as React.LegacyRef<HTMLDivElement>}
        id={id}
        className="px-4 md:px-10 lg:px-36 bg-cover bg-no-repeat bg-center h-[60vh] md:h-[80vh]"
        style={{ backgroundImage: `url(${backgroundImage?.url})` }}
      >
        <div className="flex items-center justify-between py-6">
          <div className="flex items-center">
            <img className='h-[40px]' src={logo?.url} alt="logo" />
          </div>

          <div className="flex items-center space-x-8">

            <button
              onClick={handleLoginClick}
              className="block px-6 py-3 text-white font-medium rounded-full transition-colors"
              style={{ backgroundColor: themeColor}}
            >
              {buttonLabel}
            </button>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-0 items-center justify-end m-0 md:m-[60px] lg:m-0 mt-4 md:mt-[60px] lg:mt-[100px]">
          <div className="overflow-hidden w-full">
            <h1
              className="font-bold text-black text-2xl md:text-[56px] leading-tight md:mt-2 mx-4 lg:mx-0 lg:mx-0 lg:mx-0line-clamp-3 text-center lg:text-left
                [&:not(:root)]:md:text-[min(5vh,56px)]"
              title="Nullam lobortis dignissim magna sed porta mauris"
            >
              {title}
            </h1>
            <p
              className="text-black text-xs md:text-[20px] md:mt-4 max-w-xl leading-relaxed line-clamp-3
                [&:not(:root)]:md:text-[min(2.5vh,20px)]"
              title="Nullam lobortis dignissim magna, sed porta mauris vulputate sed. Sed sapien felis, elementum ac hendrerit non, fermentum nec diam. Proin vestibulum eu urna et venenatis."
            >
              {subtitle}
            </p>
            <button
              onClick={() => scrollToSection('3aC4TWeOoS')}
              className="mt-4 flex items-center justify-center w-10 h-10 border border-[#000000] rounded-full hover:bg-[#00AFAF] hover:text-white transition"
            >
              <GTGSIcon name="arrow-down" size={17}></GTGSIcon>
            </button>
          </div>

          <div className="w-[90%] justify-center md:w-full flex md:justify-end">
            {/* <InfoForm /> */}
          </div>
        </div>

        <ChatButton />
      </header>

    </>
  );
}

TravelHeader.defaultProps = {
  themeColor: "#00C1B4",
  backgroundImage: {
    url: '/travel/travel-header-bg.png',
    name: 'Background Image',
  },
  logo: { url: '/images/logo/logo-gtg-green.png', name: 'Logo' },
  title: 'Nullam lobortis dignissim magna sed porta mauris',
  subtitle: 'Nullam lobortis dignissim magna, sed porta mauris vulputate sed. Sed sapien felis, elementum ac hendrerit non, fermentum nec diam. Proin vestibulum eu urna et venenatis.',
  buttonLabel: 'Get it now',
  buttonLink: 'https://app.gtgcrm.com/',
};

TravelHeader.craft = {
  props: TravelHeader.defaultProps,
  related: {
    settings: TravelHeaderSetting,
  },
  displayName: 'Travel Header',
};

export default TravelHeader;
