import { GTGSIcon } from '@/components/GTGS';
import { useNode } from '@craftjs/core';
import React, { useState } from 'react';
import { TravelServiceProps } from '../types';
import TravelServiceSetting from './settings/TravelServiceSetting';

const accordionItems = [
  {
    title: "In sed porta enim, in gravida est",
    content: "Phasellus vel ornare nunc. Pellentesque malesuada metus mauris, in tristique justo convallis in."
  },
  {
    title: "In 2 sed porta enim, in gravida est",
    content: "Phasellus vel ornare nunc. Pellentesque malesuada metus mauris, in tristique justo convallis in."
  },
  {
    title: "In 3 sed porta enim, in gravida est",
    content: "Phasellus vel ornare nunc. Pellentesque malesuada metus mauris, in tristique justo convallis in."
  }
]
function TravelService({ title, description, backgroundImage, listItem, themeColor }: TravelServiceProps) {
  const {
    connectors: { connect },
    id,
  } = useNode();

  const [openItem, setOpenItem] = useState<number | null>(0);

  const toggleAccordion = (index: number) => {
    setOpenItem(openItem === index ? null : index);
  };

  return (
    <div
      id={id}
      ref={connect as React.LegacyRef<HTMLDivElement>}
      className="relative px-4 md:px-10 lg:px-36 bg-cover bg-center h-[80vh]"
      style={{ backgroundImage: `url(${backgroundImage.url})` }}
    >
      {/* Content Container - adjusted background opacity and padding */}
      <div className="w-[90%] md:max-w-2xl bg-white/70 rounded-lg p-6 absolute right-1/2 lg:right-36 top-1/2 translate-x-1/2 lg:translate-x-0 -translate-y-1/2 max-h-[70vh] md:max-h-none overflow-y-auto md:overflow-visible">
        {/* Adjusted heading styles */}
        <h2 className="text-2xl md:text-[32px] font-semibold text-[#183B56] mb-4 md:mb-6">
          {title}
        </h2>
        {/* Adjusted paragraph text color */}
        <p className="text-[#5A7184] mb-4 md:mb-6">
          {description}
        </p>

        {/* Accordion Items - adjusted spacing and colors */}
        <div className="md:space-y-2">
          {listItem.map((item, index) => (
            <div key={index}>
              <div
                onClick={() => toggleAccordion(index)}
                className="flex justify-between items-center w-full py-4 gap-2 cursor-pointer"
              >
                <span className="flex items-center gap-2">
                  <GTGSIcon name={openItem === index ? "circle-fill" : "circle"} fill={themeColor || "#00C1B4"} size={16} />
                  <span className="text-[#183B56] font-bold text-base md:text-lg">{item.title}</span>
                </span>
                <span className={`transform transition-transform ${openItem === index ? 'rotate-180' : ''}`}>
                  <GTGSIcon name="chevron-down" fill={"#5A7184"} size={16} />
                </span>
              </div>
              {openItem === index && (
                <span className="text-[#183B56]">
                  {item.content}
                </span>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

TravelService.defaultProps = {
  title: 'Integer consectetur nibh mi, vel pretium diam tincidunt ut',
  description: 'Phasellus aliquam mattis lacus, a malesuada massa ultricies nec. Nam tempus justo ipsum, sed gravida turpis congue sit amet.',
  backgroundImage: { url: '/travel/service-bg.png', name: 'bg' },
  listItem: accordionItems,
  themeColor: '#00C1B4',
};

TravelService.craft = {
  props: TravelService.defaultProps,
  related: {
    settings: TravelServiceSetting,
  },
};

export default TravelService;
