import { useNode } from '@craftjs/core';
import { Button, Collapse, Flex } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import AssetUpload from '@/components/AssetUpload';

const StyledCollapse = styled(Collapse)`
    .ant-collapse-header {
        align-items: center !important;
    }
`;

const TestimonialItem = ({ item, index, onChangeItem }) => {
  return (
    <Flex vertical gap="middle">
      <div>
        <label className="block font-bold text-gray-700">Content</label>
        <textarea
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          placeholder="Testimonial content"
          value={item.content}
          onChange={(e) => onChangeItem(index, 'content', e.target.value)}
        />
      </div>
      <div>
        <label className="block font-bold text-gray-700">Author</label>
        <input
          type="text"
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          placeholder="Author name"
          value={item.author}
          onChange={(e) => onChangeItem(index, 'author', e.target.value)}
        />
      </div>
      <div>
        <label className="block font-bold text-gray-700">Job Title</label>
        <input
          type="text"
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          placeholder="Job title"
          value={item.jobTitle}
          onChange={(e) => onChangeItem(index, 'jobTitle', e.target.value)}
        />
      </div>
      <div>
        <label className="block font-bold text-gray-700">Avatar</label>
        <Flex align="center" justify="space-between">
          <span>{item.avatarUrl?.name || 'No image selected'}</span>
          <AssetUpload
            onSelect={(file) => onChangeItem(index, 'avatarUrl', file)}
          >
            <Button type="link">
              {item.avatarUrl?.url ? 'CHANGE' : 'SELECT'} IMAGE
            </Button>
          </AssetUpload>
        </Flex>
      </div>
    </Flex>
  );
};

const TravelTestimonialSetting = () => {
  const {
    actions: { setProp },
    themeColor,
    listItem,
    intervalTimeSlide
  } = useNode((node) => ({
    themeColor: node.data.props.themeColor,
    listItem: node.data.props.listItem,
    intervalTimeSlide: node.data.props.intervalTimeSlide
  }));

  const handleThemeColorChange = (value: string) => {
    setProp((props: any) => (props.themeColor = value), 1000);
  };

  const handleChangeItem = (index: number, field: string, value: any) => {
    setProp((props: any) => {
      const newListItem = [...props.listItem];
      if (field === 'avatarUrl') {
        newListItem[index].avatarUrl = { url: value.url, name: value.name };
      } else {
        newListItem[index][field] = value;
      }
      props.listItem = newListItem;
    }, 1000);
  };

  const handleAddItem = () => {
    setProp((props: any) => {
      const newListItem = [
        ...props.listItem,
        {
          content: "New testimonial content",
          author: "New Author",
          jobTitle: "Job Title",
          avatarUrl: { url: "/travel/testimonial.png", name: "testimonial" }
        }
      ];
      props.listItem = newListItem;
    }, 1000);
  };

  const handleRemoveItem = (index: number) => {
    setProp((props: any) => {
      const newListItem = [...props.listItem];
      newListItem.splice(index, 1);
      props.listItem = newListItem;
    }, 1000);
  };

  return (
    <Flex vertical gap="middle">
      {/* Interval time slide */}
      <div>
        <label className="block font-bold text-gray-700">Interval time slide</label>
        <input
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          type="text"
          value={intervalTimeSlide}
          onChange={(e) => setProp((props: any) => (props.intervalTimeSlide = e.target.value))}
        />
      </div>

      <StyledCollapse
        defaultActiveKey={['0']}
        items={listItem.map((item, index) => ({
          key: index,
          label: (
            <Flex align="center" justify="space-between">
              <span>Testimonial {index + 1}</span>
              <Button
                type="text"
                danger
                icon={<DeleteOutlined />}
                onClick={(e) => {
                  e.stopPropagation();
                  handleRemoveItem(index);
                }}
              />
            </Flex>
          ),
          children: (
            <TestimonialItem
              item={item}
              index={index}
              onChangeItem={handleChangeItem}
            />
          ),
        }))}
      />

      <Button
        type="dashed"
        onClick={handleAddItem}
        block
        icon={<PlusOutlined />}
      >
        Add Testimonial
      </Button>
    </Flex>
  );
};

export default TravelTestimonialSetting;
